import { Link, useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { UserContext } from "../../userProvider";
import dayjs from 'dayjs';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import profilePicture from '../../image/profile-picture.png'
import azcoin from '../../image/azcoin-des2.png'
import bronze from '../../image/bronze.png'
import silver from '../../image/Silver.png'
import gold from '../../image/Gold.png'
import platium from '../../image/Platium.png'
import diamond from '../../image/Diamond.png'
import hotelbell from '../../image/hotel-bell.png'
import onigiri from '../../image/onigiri.png'
import onsen from '../../image/onsen.png'
import massage from '../../image/foot-massage.png'
import logo from '../../image/logo-white.png'
export default function Setting() {
  const { logout } = useContext(UserContext);
  const [userData, setUserData] = useState()
  const [familyName, setFamilyName] = useState('')
	const [givenName, setGivenName] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en'
  const location = useLocation();
  const navigate = useNavigate();
  const genderValue = t("gender", { returnObjects: true });
  const [userImage, setUserImage] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [day, setDay] = useState('')
	const [month, setMonth] = useState('')
	const [year, setYear] = useState('')
	const [gender, setGender] = useState('')
  const [voucher, setVoucher] = useState([])
  const [yourVoucher, setYourVoucher] = useState([])
  const [usedVoucher, setUsedVoucher] = useState([])
  console.log("🚀 ~ Setting ~ usedVoucher:", usedVoucher)
  const [listGuest, setListGuest] = useState([])
  const [listReservation, setListReservation] = useState([])
  const [coin, setCoin] = useState()
  const [rank, setRank] = useState()
  const [_id, set_ID] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [formatCreatedAt, setFormatCreatedAt] = useState(null);
  const colRef = useRef();
  const [screenWidth, setScreenWidth] = useState();

  const getData = async () => {
    try {
      const response = await fetch(`/api/user/wIzo9Vlr-GIpz-cuQ9-R1Q8-9I5KIyEFShl`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      
      });
      
      if (!response.ok) {
      throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      setUserData(responseData)   
    } 
    catch (error) {
      console.error('Error:', error);
    }
}

  useEffect(() => {
    const updateWidth = () => {
      if (colRef.current) {
        const colWidth = colRef.current.getBoundingClientRect().width;
        // console.log("colWidth:", colWidth); // Debug width
        if (colWidth > 0) {
          const widthScale = window.innerWidth / colWidth;
          setScreenWidth(window.innerWidth / widthScale);
        }
      } else {
        console.log("colRef.current is null");
      }
    };
  
    setTimeout(updateWidth, 0); // Handle timing issue
    window.addEventListener("resize", updateWidth);
  
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    console.log("screenWidth updated:", screenWidth);
  }, [screenWidth]);
  // const screenWidth = window.innerWidth/widthScale
  // const screenWidth = window.innerWidth/1.42

  const bronzeSpend = 20000000
  const silverSpend = 50000000
  const goldSpend = 100000000
  const platinumSpend = 150000000

  const spendBronze = 12000000
  const spendSilver = 45000000
  const spendGold = 70000000
  const spendPlatinum = 120000000
  const spendDiamond = 180000000

  const widthSpendBronze = (screenWidth)/(bronzeSpend/spendBronze)
  const widthSpendSilver = (screenWidth)/(silverSpend/spendSilver)
  const widthSpendGold = (screenWidth)/(goldSpend/spendGold)
  const widthSpendPlatinum = (screenWidth)/(platinumSpend/spendPlatinum)

  const pointBronze = (spendBronze*1000)/bronzeSpend
  const pointSilver = (spendSilver*2500)/silverSpend
  const pointGold = (spendGold*5000)/goldSpend
  const pointPlatinum = (spendPlatinum*7500)/platinumSpend
  const pointDiamond = (spendDiamond*7500)/platinumSpend


  const [navigateOnUpdate, setNavigateOnUpdate] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex1, setSelectedIndex1] = useState(0);
  //   // State to track when the index is loaded
    const [isLoaded, setIsLoaded] = useState(false);
  
  //   // Retrieve the saved index on component mount
    useEffect(() => {
      const savedIndex = parseInt(localStorage.getItem('selectedTabIndex'), 10);
      if (!isNaN(savedIndex)) {
        setSelectedIndex(savedIndex);
      }
      setIsLoaded(true); // Set as loaded after retrieving the value
    }, []);
  
  //   // Save the selected index whenever it changes
    useEffect(() => {
      if (isLoaded) {
        localStorage.setItem('selectedTabIndex', selectedIndex);
      }
    }, [selectedIndex, isLoaded]);
  
  //   // Handle tab selection
    const handleSelect = (index) => {
      setSelectedIndex(index);
    };
    const handleSelect1 = (index) => {
      setSelectedIndex1(index);
    };
  
  const inputRef1 = useRef(null);
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);
	const inputRef4 = useRef(null);
	const inputRef5 = useRef(null);
	const inputRef6 = useRef(null);

	const handleKeyDown = (e, ref) => {
		const input = ref.current;    
		const { selectionStart, selectionEnd } = input;
	
		if (e.key === 'ArrowLeft') {
		  e.preventDefault();
		  // Move cursor left
		  if (selectionStart > 0) {
			input.setSelectionRange(selectionStart - 1, selectionStart - 1);
		  }
		} else if (e.key === 'ArrowRight') {
		  e.preventDefault();
		  // Move cursor right
		  if (selectionEnd < input.value.length) {
			input.setSelectionRange(selectionStart + 1, selectionStart + 1);
		  }
		}
	  };
  // const [userDataSocial, setUserDataSocial] = useState()
  // const userDataSocial = JSON.parse(localStorage.getItem('userData'))
  const handleLogout = () => {
    logout();
    navigate(`/${language}/`);
    window.location.reload();
  };

const splitDate = (dateString) => {
  const [daySplit, monthSplit, yearSplit] = dateString.split('-')
  setDay(daySplit)
  setMonth(monthSplit)
  setYear(yearSplit)
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  });

  return formatter.format(date);
};

const remainDate = (dateString) => {
  const futureDate = new Date(dateString);
  const today = new Date();
  const differenceInMs = futureDate - today;
  const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
  // const differenceInHours = Math.ceil(differenceInMs / (1000 * 60 * 60));
  // const differenceInMinutes = Math.ceil(differenceInMs / (1000 * 60));
  return differenceInDays
    // hours: differenceInHours,
    // minutes: differenceInMinutes,
};

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [index, setIndex] = useState(0)
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (userData) {
      const image = userData.data.avatar || profilePicture ;
      const name = userData.data.name  ||  ''
      setUserImage(image);
      setFamilyName(userData.data.familyName || '')
      setGivenName(userData.data.givenName || '')
      setUserEmail(userData.data.email || '')
      setPhone(userData.data.phone || '')
      setBirthDate(userData.data.birthDate  || '')
      splitDate(userData.data.birthDate || '')
      setGender(userData.data.gender || '')
      setListGuest(userData.data.listGuest || [])
      setYourVoucher(userData.data.voucher || [])
      setListReservation(userData.data.reservationHistory || [])
      setCoin(userData.data.azCoin || '')
      setRank(userData.data.rank || '')
      set_ID(userData.data._id || '')
      setCreatedAt(userData.data.createdAt || '')
    }
  }, [userData]);

  useEffect(() => {
    if (createdAt) {
      const formattedDate = new Date(createdAt);
      setFormatCreatedAt(isNaN(formattedDate) ? null : formattedDate);
    }
  }, [createdAt]);

  useEffect(() => {
    if (voucher.length > 0) {
      setVoucher(voucher)
    }
  }, [voucher]);

  useEffect(() => {
    if (navigateOnUpdate) {
      navigate(`/${language}/reservation/`, {
        state: {
          familyName: userData.data.listGuest[index].familyName || '',
          givenName: userData.data.listGuest[index].givenName || '',
          email: userData.data.listGuest[index].email || '',
          birthDay: userData.data.listGuest[index].day,
          birthMonth: userData.data.listGuest[index].month,
          birthYear: userData.data.listGuest[index].year,
          gender: userData.data.listGuest[index].gender || '',
          smoking: userData.data.listGuest[index].smoking || '',
          vat: userData.data.listGuest[index].vat || ''
        }
      });
      setNavigateOnUpdate(false); // Reset navigation trigger
    }
  }, [navigateOnUpdate, navigate, language, userData, index]);

  function DayPicker() {
		const minDay = 1;
		const maxDay = 31;
		const birthDay = 0;
		const options = [];
		for (let i = minDay; i <= maxDay; i++) {
		  const day = birthDay + i;
		  options.push(
			<option value={day} key={day}>
			  {day <=9 ? '0' + day : day}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.day ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={day}
				onChange={(e) => {
					setDay(e.target.value)
					errors.day = ''
			}}
		  >
			<option className="first-opt">
			  {t("reservation.day")}
			</option>
			{options}
		  </select>
		);
	  }
	function MonthPicker() {
		const minMonth = 0;
		const maxMonth = 11;
		const birthMonth = 1;
	
		const options = [];
		for (let i = minMonth; i <= maxMonth; i++) {
		  const month = birthMonth + i;
		  options.push(
			<option value={month} key={month}>
			  {month <=9 ? '0' + month : month}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.month ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={month}
				onChange={(e) => { 
					setMonth(e.target.value)
					errors.month = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.month")}
			</option>
			{options}
		  </select>
		);
	  }
	function YearPicker() {
		const minYear = 1925;
		const maxYear = 2005;
		const birthYear = 0;
	
		const options = [];
		for (let i = minYear; i <= maxYear; i++) {
		  const year = birthYear + i;
		  options.push(
			<option value={year} key={year}>
			  {year}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.year ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={year}
				onChange={(e) => {
					setYear(e.target.value)
					errors.year = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.year")}
			</option>
			{options}
		  </select>
		);
	  }
  function EditGuestInfo(props) {
      const [familyName, setFamilyName] = useState('');
      const [givenName, setGivenName] = useState('');
      const [gender, setGender] = useState('')
      const [day, setDay] = useState('');
      const [month, setMonth] = useState('');
      const [year, setYear] = useState('');
      const [email, setEmail] = useState('');
      const [specialRequest, setSpecialRequest] = useState('');
      const [smoking, setSmoking] = useState('');
      const [errors, setErrors] = useState({})
      const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
      const [passport, setPassport] = useState('')
      const [vat, setVat] = useState('')
      const [vatNote, setVatNote] = useState('')
         
      useEffect(() => {
        if (userData && userData.data && userData.data.listGuest && userData.data.listGuest[index]) {
          const guest = userData.data.listGuest[index];
          setDay(guest.day)
          setMonth(guest.month)
          setYear(guest.year)
          setFamilyName(guest.familyName);
          setGivenName(guest.givenName);
          setGender(guest.gender);
          setEmail(guest.email);
          setSmoking(guest.smoking);
          setVat(guest.vat);
        }
      }, [userData, index]);
      const handleImageChange = (e) => {
        e.preventDefault();
    
        const file = e.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      };
      
      const {t} = useTranslation()
    
      function DayPicker() {
        const minDay = 1;
        const maxDay = 31;
        const birthDay = 0;
        const options = [];
        for (let i = minDay; i <= maxDay; i++) {
          const day = birthDay + i;
          options.push(
          <option value={day} key={day}>
            {day <=9 ? '0' + day : day}
          </option>
          );
        }
        return (
          <select
          className={ errors.day ? "form__content validate_failed" : "form__content mb-0 mr-0"}
            style={{fontSize: '1.2rem'}}
            value={day}
            onChange={(e) => {
              setDay(e.target.value)
              errors.day = ''
          }}
          >
          <option className="first-opt">
            {t("reservation.day")}
          </option>
          {options}
          </select>
        );
        }
      function MonthPicker() {
        const minMonth = 0;
        const maxMonth = 11;
        const birthMonth = 1;
      
        const options = [];
        for (let i = minMonth; i <= maxMonth; i++) {
          const month = birthMonth + i;
          options.push(
          <option value={month} key={month}>
            {month <=9 ? '0' + month : month}
          </option>
          );
        }
        return (
          <select
          className={ errors.month ? "form__content validate_failed" : "form__content mb-0 mr-0"}
            style={{fontSize: '1.2rem'}}
            value={month}
            onChange={(e) => { 
              setMonth(e.target.value)
              errors.month = ''
            }}
          >
          <option className="first-opt">
            {t("reservation.month")}
          </option>
          {options}
          </select>
        );
        }
      function YearPicker() {
        const minYear = 1925;
        const maxYear = 2005;
        const birthYear = 0;
      
        const options = [];
        for (let i = minYear; i <= maxYear; i++) {
          const year = birthYear + i;
          options.push(
          <option value={year} key={year}>
            {year}
          </option>
          );
        }
        return (
          <select
          className={ errors.year ? "form__content validate_failed" : "form__content mb-0 mr-0"}
            style={{fontSize: '1.2rem'}}
            value={year}
            onChange={(e) => {
              setYear(e.target.value)
              errors.year = ''
            }}
          >
          <option className="first-opt">
            {t("reservation.year")}
          </option>
          {options}
          </select>
        );
        }
    
      const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      const validate = () => {
        let errors = {}
        let isVaLid = true
      
        if (!familyName) {
          errors.familyName = 'required';
          isVaLid = false
        }
        if (!givenName) {
          errors.givenName = 'required';
          isVaLid = false
        } 
        if (!gender) {
          errors.gender = 'required';
          isVaLid = false
        } 
        if (!email) {
          errors.email = 'required';
          isVaLid = false
      
        } 
        else if (!validateEmail(email)) {
          errors.email = 'Invalid email format';
          isVaLid = false
      
        }
        if (!phone) {
          errors.phone = 'required';
          isVaLid = false
        }
        setErrors(errors);
        return isVaLid
      }
      const editGuest = async (data) => {
        try {
          const response = await fetch('/api/user/edit/ff9f3f78-aedc-4e56-a467-74cd5f411cdb', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include', 
            body: JSON.stringify(data), 
          });
      
          if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Network response was not ok: ${errorDetails.msg || response.statusText}`);
          }
      
          const responseData = await response.json();
          // console.log("🚀 ~ guestControl ~ responseData:", responseData);
      
          return responseData;
        } catch (error) {
          console.error("Error:", error.message);
        }
      };
      
      const editData = {
        index: index,
        familyName,
        givenName,
        gender,
        day, 
        month, 
        year,
        email,
        specialRequest,
        smoking,
        imagePreviewUrl,
        passport,
        vat,
        vatNote
      }
      const handleSubmit = (e) => {
        e.preventDefault();
  
        if(validate()){
          editGuest(editData)
          setModalShow2(false)
          window.location.reload()
        }
      }
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
             {t("login.edit-guest")}
            </Modal.Title>
            <Button variant="light" onClick={props.onHide}>
              <i className="fa-solid fa-xmark purple"></i>
            </Button>
          </Modal.Header>
          <div className="row p-5 pb-0">
            <div className="col-md-12 massage_reservation" style={{borderRight: 'none'}}>
              <Modal.Body>
              <form onSubmit={handleSubmit}>
              <h2>{t("login.guest-info")}</h2>
              <div className="row pl-3 pr-3">
                <div className="col-md-6">
                <input
                ref={(inputRef1)}
                onKeyDown={(e) => handleKeyDown(e, inputRef1)}
                  placeholder={t("reservation.family-name")}
                  type="text"
                  className={errors.familyName ? 'col-md-12 form__content mb-0 validate_failed' : 'col-md-12 form__content mb-0'}
                  value={familyName}
                  onChange={(e) => {
                    setFamilyName(e.target.value);
                    errors.familyName = ''
                  }}
                />
                </div>
                <div className="col-md-6 mt-3 mt-md-0">
                <input
                  ref={(inputRef6)}
                  onKeyDown={(e) => handleKeyDown(e, inputRef6)}
                  placeholder={t("reservation.given-name")}
                  type="text"
                  className={errors.givenName ? 'col-md-12 form__content mb-0 validate_failed' : 'col-md-12 form__content mb-0'}
                  value={givenName}
                  onChange={(e) => {
                    setGivenName(e.target.value);
                    errors.givenName = ''
                  }}
                />
                </div>
              </div>
              <div className="row pl-3 pr-3">
              <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("reservation.gender")}</div>
              <div className="col-md-4">
              <input
                  type="radio"
                  name="gender"
                  id="gMale"
                  value="Mr."
                  checked={gender === "Mr."}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="gMale">{t("reservation.mr")}</label>
                  </div>
              <div className="col-md-4">
                <input
                  type="radio"
                  name="gender"
                  id="gFemale"
                  value="Ms."
                  checked = {gender === "Ms."}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="gFemale">{t("reservation.ms")}</label>
              </div>
              </div>
                <div className="row pt-0">
                  <div className="login_input">
                  <div style={{fontSize: '1.2rem',color: !day || !month || !year ? '#8597a3' : '#482979', textAlign: 'left'}}>{t("reservation.birth-date")}</div>
                  </div>
                    <div className="col-md-4 pl-md-0">
                    <div className="login_input">
                      <DayPicker />
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="login_input">
                      <MonthPicker />

                    </div>
                    </div>
                    <div className="col-md-4 pr-md-0">
                    <div className="login_input">
                      <YearPicker />
                    </div>
                    </div>
                </div>
              <div className="row pl-3 pr-3">
                    <input
                    ref={(inputRef2)}
                    onKeyDown={(e) => handleKeyDown(e, inputRef2)}
                  type="text"
                  className={errors.email ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  value={email}
                  placeholder={t("service_massage.email")}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    errors.email = ''
                  }}
                />
                <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("room_hbt1.type")}</div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    name="roomType"
                    id="no-smk"
                    value="Non Smoking"
                    checked = {smoking === 'Non Smoking'}
                    onClick={(e) => setSmoking(e.target.value)}
                  />
                  <label htmlFor="no-smk">{t("reservation.non-smk")}</label>
                  </div>
              <div className="col-md-4">
                  <input
                    type="radio"
                    name="roomType"
                    id="smk"
                    value="Smoking"    
                    checked = {smoking === 'Smoking'}  
                    onClick={(e) => setSmoking(e.target.value)}
                  />
                  <label htmlFor="smk">{t("reservation.smk")}</label>
                </div>
                  <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("login.passport-image")}</div>
                <div className="col-md-4">
                  <input type="file" onChange={handleImageChange} />
                  {imagePreviewUrl && (
                        <img className="mt-3" src={imagePreviewUrl} alt="Image Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                    )}
                </div>
                <input
                  ref={(inputRef3)}
                  onKeyDown={(e) => handleKeyDown(e, inputRef3)}
                  type="text"
                  className='col-md-12 form__content mt-4'
                  style={{textTransform: 'uppercase'}}
                  value={passport}
                  placeholder={t("login.passport")}
                  onChange={(e) => {
                    setPassport(e.target.value)
                  }}
                />
                <div style={{color: '#8597a3', fontSize: '1.2rem'}}>VAT: </div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    name="vatInvoice"
                    id="no-need"
                    className="VATInvoice"
                    value="No Necessary"
                    checked = {vat === 'No Necessary'}
                    onClick={(e)=> setVat(e.target.value)}
                  />
                  <label htmlFor="no-need">{t("reservation.n-need")}</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    name="vatInvoice"
                    id="need"
                    className="VATInvoice"
                    value="Necessary"
                    onClick={(e)=> setVat(e.target.value)}
                  />
                  <label htmlFor="need">{t("reservation.need")}</label>
                </div>
                <textarea
                  ref={(inputRef4)}
                  onKeyDown={(e) => handleKeyDown(e, inputRef4)}
                  className="text-note"
                  cols="40"
                  rows="2"
                  placeholder={`VAT ${t("service_massage.special")}`}
                  value={vatNote}
                  onChange={(e) => setVatNote(e.target.value)}></textarea>
                <textarea
                ref={(inputRef5)}
                onKeyDown={(e) => handleKeyDown(e, inputRef5)}
                  className="text-note mt-5"
                  cols="40"
                  rows="4"
                  placeholder={t("service_massage.special")}
                  value={specialRequest}
                  onChange={(e) => setSpecialRequest(e.target.value)}></textarea>
                <div className="row justify-content-center">
                  <button
                    id="send"
                    className="button-57 send-btn col-2"
                    style={{backgroundColor:"#482979", border: '1px solid #482979'}}
                    type="submit"
                  >
                    <span className="text" style={{ color: "#fff" }}>
                      Save
                    </span>
                    <span className="d-flex align-item-center">
                      <i
                        className="fa-regular fa-floppy-disk"
                        style={{ fontSize: "2.8rem", lineHeight: "2.8rem", color: "#482979" }}
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
              
              </form>
              </Modal.Body>
            </div>
          </div>
          <Modal.Footer>
            <button
              className="button-57 close-btn"
              role="button"
              onClick={props.onHide}
            >
              <span className="text" style={{ color: "#fff" }}>
                {t('service_massage.close')}                                                
              </span>
              <span>
                <i
                  className="fa-solid fa-xmark red"
                  style={{ fontSize: "1.8rem", lineHeight: "2.8rem" }}
                ></i>
              </span>
            </button>
          </Modal.Footer>
        </Modal>
      );
    }
  function InputGuestInfo(props) {
    const [familyName, setFamilyName] = useState('');
    const [givenName, setGivenName] = useState('');
    const [gender, setGender] = useState('Mr.')
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [email, setEmail] = useState('');
    const [specialRequest, setSpecialRequest] = useState('');
    const [smoking, setSmoking] = useState('Non Smoking');
    const [errors, setErrors] = useState({})
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [passport, setPassport] = useState('')
    const [vat, setVat] = useState('No Necessary')
    const [vatNote, setVatNote] = useState('')

          const handleKeyDown = (e, ref) => {
            const input = ref.current;    
            const { selectionStart, selectionEnd } = input;
          
            if (e.key === 'ArrowLeft') {
              e.preventDefault();
              // Move cursor left
              if (selectionStart > 0) {
              input.setSelectionRange(selectionStart - 1, selectionStart - 1);
              }
            } else if (e.key === 'ArrowRight') {
              e.preventDefault();
              // Move cursor right
              if (selectionEnd < input.value.length) {
              input.setSelectionRange(selectionStart + 1, selectionStart + 1);
              }
            }
            };
    const handleImageChange = (e) => {
      e.preventDefault();

      const file = e.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    };
    
    const {t} = useTranslation()

    function DayPicker() {
      const minDay = 1;
      const maxDay = 31;
      const birthDay = 0;
      const options = [];
      for (let i = minDay; i <= maxDay; i++) {
        const day = birthDay + i;
        options.push(
        <option value={day} key={day}>
          {day <=9 ? '0' + day : day}
        </option>
        );
      }
      return (
        <select
        className={ errors.day ? "form__content validate_failed" : "form__content mb-0 mr-0"}
          style={{fontSize: '1.2rem'}}
          value={day}
          onChange={(e) => {
            setDay(e.target.value)
            errors.day = ''
        }}
        >
        <option className="first-opt">
          {t("reservation.day")}
        </option>
        {options}
        </select>
      );
      }
    function MonthPicker() {
      const minMonth = 0;
      const maxMonth = 11;
      const birthMonth = 1;
    
      const options = [];
      for (let i = minMonth; i <= maxMonth; i++) {
        const month = birthMonth + i;
        options.push(
        <option value={month} key={month}>
          {month <=9 ? '0' + month : month}
        </option>
        );
      }
      return (
        <select
        className={ errors.month ? "form__content validate_failed" : "form__content mb-0 mr-0"}
          style={{fontSize: '1.2rem'}}
          value={month}
          onChange={(e) => { 
            setMonth(e.target.value)
            errors.month = ''
          }}
        >
        <option className="first-opt">
          {t("reservation.month")}
        </option>
        {options}
        </select>
      );
      }
    function YearPicker() {
      const minYear = 1925;
      const maxYear = 2005;
      const birthYear = 0;
    
      const options = [];
      for (let i = minYear; i <= maxYear; i++) {
        const year = birthYear + i;
        options.push(
        <option value={year} key={year}>
          {year}
        </option>
        );
      }
      return (
        <select
        className={ errors.year ? "form__content validate_failed" : "form__content mb-0 mr-0"}
          style={{fontSize: '1.2rem'}}
          value={year}
          onChange={(e) => {
            setYear(e.target.value)
            errors.year = ''
          }}
        >
        <option className="first-opt">
          {t("reservation.year")}
        </option>
        {options}
        </select>
      );
      }

    const validateEmail = (email) => {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    const validate = () => {
      let errors = {}
      let isVaLid = true
    
      if (!familyName) {
        errors.familyName = 'required';
        isVaLid = false
      }
      if (!givenName) {
        errors.givenName = 'required';
        isVaLid = false
      } 
      if (!gender) {
        errors.gender = 'required';
        isVaLid = false
      } 
      if (!email) {
        errors.email = 'required';
        isVaLid = false
    
      } 
      else if (!validateEmail(email)) {
        errors.email = 'Invalid email format';
        isVaLid = false
    
      }
      setErrors(errors);
      return isVaLid
    }
    const addGuest = async (data) => {
      try {
        const response = await fetch('/api/user/add/ff9f3f78-aedc-4e56-a467-74cd5f411cdb', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', 
          body: JSON.stringify(data), 
        });
    
        if (!response.ok) {
          const errorDetails = await response.json();
          throw new Error(`Network response was not ok: ${errorDetails.msg || response.statusText}`);
        }
    
        const responseData = await response.json();
    
        return responseData;
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    
    const addData = {
      familyName,
      givenName,
      gender,
      day, 
      month, 
      year,
      email,
      specialRequest,
      smoking,
      imagePreviewUrl,
      passport,
      vat,
      vatNote
    }
    
    const handleSubmit = (e) => {
      e.preventDefault();

      if(validate()){
        addGuest(addData)
        setModalShow(false)
        window.location.reload()
      }
    }
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("login.add-guest")}
          </Modal.Title>
          <Button variant="light" onClick={props.onHide}>
            <i className="fa-solid fa-xmark purple"></i>
          </Button>
        </Modal.Header>
        <div className="row p-5 pb-0">
          <div className="col-md-12 massage_reservation" style={{borderRight: 'none'}}>
            <Modal.Body>
          <form onSubmit={handleSubmit}>
              <h2>{t("login.guest-info")}</h2>
              <div className="row pl-3 pr-3">
                <div className="col-md-6">
                <input
                ref={(inputRef1)}
                onKeyDown={(e) => handleKeyDown(e, inputRef1)}
                  placeholder={t("reservation.family-name")}
                  type="text"
                  className={errors.familyName ? 'col-md-12 form__content mb-0 validate_failed' : 'col-md-12 form__content mb-0'}
                  value={familyName}
                  onChange={(e) => {
                    setFamilyName(e.target.value);
                    errors.familyName = ''
                  }}
                />
                </div>
                <div className="col-md-6 mt-3 mt-md-0">
                <input
                ref={(inputRef6)}
                onKeyDown={(e) => handleKeyDown(e, inputRef6)}
                  placeholder={t("reservation.given-name")}
                  type="text"
                  className={errors.givenName ? 'col-md-12 form__content mb-0 validate_failed' : 'col-md-12 form__content mb-0'}
                  value={givenName}
                  onChange={(e) => {
                    setGivenName(e.target.value);
                    errors.givenName = ''
                  }}
                />
                </div>
              </div>
              <div className="row pl-3 pr-3">
              <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("reservation.gender")}</div>
              <div className="col-md-4">
              <input
                  type="radio"
                  name="gender"
                  id="gMale"
                  value="Mr."
                  checked={gender === "Mr."}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="gMale">{t("reservation.mr")}</label>
                  </div>
              <div className="col-md-4">
                <input
                  type="radio"
                  name="gender"
                  id="gFemale"
                  value="Ms."
                  checked = {gender === "Ms."}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="gFemale">{t("reservation.ms")}</label>
              </div>
              </div>
                <div className="row pt-0">
                  <div className="login_input">
                  <div style={{fontSize: '1.2rem',color: !day || !month || !year ? '#8597a3' : '#482979', textAlign: 'left'}}>{t("reservation.birth-date")}</div>
                  </div>
                    <div className="col-md-4 pl-md-0">
                    <div className="login_input">
                      <DayPicker />
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="login_input">
                      <MonthPicker />

                    </div>
                    </div>
                    <div className="col-md-4 pr-md-0">
                    <div className="login_input">
                      <YearPicker />
                    </div>
                    </div>
                </div>
              <div className="row pl-3 pr-3">
                    <input
                    ref={(inputRef2)}
                    onKeyDown={(e) => handleKeyDown(e, inputRef2)}
                  type="text"
                  className={errors.email ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                  value={email}
                  placeholder={t("service_massage.email")}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    errors.email = ''
                  }}
                />
                <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("room_hbt1.type")}</div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    name="roomType"
                    id="no-smk"
                    value="Non Smoking"
                    checked = {smoking === 'Non Smoking'}
                    onClick={(e) => setSmoking(e.target.value)}
                  />
                  <label htmlFor="no-smk">{t("reservation.non-smk")}</label>
                  </div>
              <div className="col-md-4">
                  <input
                    type="radio"
                    name="roomType"
                    id="smk"
                    value="Smoking"      
                    onClick={(e) => setSmoking(e.target.value)}
                  />
                  <label htmlFor="smk">{t("reservation.smk")}</label>
                </div>
                  <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("login.passport-image")}</div>
                <div className="col-md-4">
                  <input type="file" onChange={handleImageChange} />
                  {imagePreviewUrl && (
                        <img className="mt-3" src={imagePreviewUrl} alt="Image Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                    )}
                </div>
                <input
                  ref={(inputRef3)}
                  onKeyDown={(e) => handleKeyDown(e, inputRef3)}
                  type="text"
                  className='col-md-12 form__content mt-4'
                  style={{textTransform: 'uppercase'}}
                  value={passport}
                  placeholder={t("login.passport")}
                  onChange={(e) => {
                    setPassport(e.target.value)
                  }}
                />
                <div style={{color: '#8597a3', fontSize: '1.2rem'}}>VAT: </div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    name="vatInvoice"
                    id="no-need"
                    className="VATInvoice"
                    value="No Necessary"
                    checked = {vat === 'No Necessary'}
                    onClick={(e)=> setVat(e.target.value)}
                  />
                  <label htmlFor="no-need">{t("reservation.n-need")}</label>
                </div>
                <div className="col-md-4">
                  <input
                    type="radio"
                    name="vatInvoice"
                    id="need"
                    className="VATInvoice"
                    value="Necessary"
                    onClick={(e)=> setVat(e.target.value)}
                  />
                  <label htmlFor="need">{t("reservation.need")}</label>
                </div>
                <textarea
                  ref={(inputRef4)}
                  onKeyDown={(e) => handleKeyDown(e, inputRef4)}
                  className="text-note"
                  cols="40"
                  rows="2"
                  placeholder={`VAT ${t("service_massage.special")}`}
                  value={vatNote}
                  onChange={(e) => setVatNote(e.target.value)}></textarea>
                <textarea
                ref={(inputRef5)}
                onKeyDown={(e) => handleKeyDown(e, inputRef5)}
                  className="text-note mt-5"
                  cols="40"
                  rows="4"
                  placeholder={t("service_massage.special")}
                  value={specialRequest}
                  onChange={(e) => setSpecialRequest(e.target.value)}></textarea>
                <div className="row justify-content-center">
                  <button
                    id="send"
                    className="button-57 send-btn col-2"
                    style={{backgroundColor:"#482979", border: '1px solid #482979'}}
                    type="submit"
                  >
                    <span className="text" style={{ color: "#fff" }}>
                      Save
                    </span>
                    <span className="d-flex align-item-center">
                      <i
                        className="fa-regular fa-floppy-disk"
                        style={{ fontSize: "2.8rem", lineHeight: "2.8rem", color: "#482979" }}
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
              
              </form>
            </Modal.Body>
          </div>
        </div>
        <Modal.Footer>
          <button
            className="button-57 close-btn"
            role="button"
            onClick={props.onHide}
          >
            <span className="text" style={{ color: "#fff" }}>
              {t('service_massage.close')}
            </span>
            <span>
              <i
                className="fa-solid fa-xmark red"
                style={{ fontSize: "1.8rem", lineHeight: "2.8rem" }}
              ></i>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    );
    }

    const UserRank = () => {
      if(rank == "gold"){
        return <img style={{marginLeft:10, cursor:'pointer'}} src = {gold} className="rank-icon" onClick={() => setModalShow4(true)} />
      }else if(rank == "silver"){
        return <img style={{marginLeft:10, cursor:'pointer'}} src = {silver} className="rank-icon" onClick={() => setModalShow4(true)} />
      }else if(rank == "bronze"){
        return <img style={{marginLeft:10, cursor:'pointer'}} src = {bronze} className="rank-icon" onClick={() => setModalShow4(true)} />
      }else if(rank == "diamond"){
        return <img style={{marginLeft:10, cursor:'pointer'}} src = {diamond} className="rank-icon" onClick={() => setModalShow4(true)} />
      }else if(rank == "platinum"){
        return <img style={{marginLeft:10, cursor:'pointer'}} src = {platium} className="rank-icon" onClick={() => setModalShow4(true)} />
      }else return
    }
    const handleClick = (index) => {
      // splitDateOther(userData.data.listGuest[index].birthDate)
     setNavigateOnUpdate(true)
    }
    
    const deleteAcc = async (data) => {
      try {
        const response = await fetch(`/api/user/26bed56b-5a1f-482f-b450-d43c7cebef54`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(data)
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if(responseData.status == 1){
          logout()
          navigate(`/${language}/`,{state: {msg: responseData.msg}})
          window.location.reload()
        }
      } catch (error) {
        console.error('Error:', error);
    }
  };
  
  const deleteData = {
    userId: _id
  }

  function ConfirmDelete(props) {
    return(
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
        Warning: Account Deletion
        </Modal.Title>
        <Button variant="light" onClick={props.onHide}>
          <i className="fa-solid fa-xmark purple"></i>
        </Button>
      </Modal.Header>
      <div className="row p-5 pb-0">
        <div className="col-md-12 massage_reservation" style={{borderRight: 'none'}}>
          <Modal.Body>
          <div style={{fontSize: '1.4rem'}}>
              <p>Deleting your account is a permanent action and cannot be undone. Once your account is deleted:</p>
              <ul>
                <li>+ All your data, including messages, contacts, and any other personal information, will be permanently erased.</li>
                <li>+ You will lose access to any services or subscriptions associated with this account.</li>
                <li>+ Any content you have shared or posted may no longer be accessible.</li>
              </ul>
              <p>Please ensure you have saved any important information before proceeding. If you are sure you want to delete your account, click "Delete Account." Otherwise, you can cancel this action and retain your account.</p>
              <p style={{textAlign: 'center', color:"#ff3131", fontWeight:'bold'}}>Are you sure you want to delete your account?</p>
            </div>
          </Modal.Body>
        </div>
      </div>
      <Modal.Footer>
      <button
          className="close-btn"
          role="button"
          onClick={()=> deleteAcc(deleteData)}
          style={{backgroundColor: "#65B741", border: '1px solid #65B741'}}
        >
          <span className="text" style={{ color: "#fff" }}>
            Confirm
          </span>
        </button>
        <button
          className="close-btn"
          role="button"
          onClick={props.onHide}
        >
          <span className="text" style={{ color: "#fff" }}>
            Cancel
          </span>
        </button>
      </Modal.Footer>
    </Modal>
    )
  }
  const editUser = async (data) => {
    try {
      const response = await fetch('/api/user/e898b05b-2372-40af-8e98-23623aa3e791', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), 
      });
  
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Network response was not ok: ${errorDetails.msg || response.statusText}`);
      }
  
      const responseData = await response.json();
  
      return responseData;
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const patchData = {
    familyName,
    givenName,
    gender,
    day,
    month, 
    year,
    email: userEmail,
    phone,
  }
  // const handlePatch = () => {
  //   editUser(patchData)
  //   window.location.reload()
  // } 
  const handlePatch = (updatedPatchData) => {
    editUser(updatedPatchData)  // Send the updated data to editUser
      .then(() => {
        window.location.reload();  // Reload after the request is successful
      })
      .catch((error) => {
        console.error("Failed to update user:", error);
      });
  };
  const deleteGuest = async (data) => {
    try {
      const response = await fetch('/api/user/delete/ff9f3f78-aedc-4e56-a467-74cd5f411cdb', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), 
      });
  
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Network response was not ok: ${errorDetails.msg || response.statusText}`);
      }
  
      const responseData = await response.json();
      window.location.reload()
  
      return responseData;
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const exChangeVoucher = async (code) => {
    try {
      const response = await fetch('/api/voucher/f16154e4-9489-463d-92e9-f77083dd7035', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(code), 
      });
  
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Network response was not ok: ${errorDetails.msg || response.statusText}`);
      }
      const responseData = await response.json();
      if(responseData.status == 1){
        getData()
        
      }else alert('Failed')
  
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const getVoucher = async () => {
    try {
      const response = await fetch('/api/voucher/108f7f4d-00c4-42a1-9ba8-8b08746faa70', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Network response was not ok: ${errorDetails.msg || response.statusText}`);
      }
      const responseData = await response.json();
      setVoucher(responseData)
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  function checkVoucher(code) {
    const checkCode = yourVoucher.find(item => item.code === code);
    if(checkCode) {
      alert(t("login.takeCoupon"))
    }else 
    {
      exChangeVoucher({code: code})
      alert(t("login.success"))
    }
  } 
  const getUsedVoucher = async (id) => {
    try {
      const response = await fetch(`/api/voucher/6767c97f-9721-425b-b867-79b6bb8b91c6/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Network response was not ok: ${errorDetails.msg || response.statusText}`);
      }
      const responseData = await response.json();
      setUsedVoucher(responseData.data)
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  useEffect(() =>{
    getVoucher()
    getUsedVoucher(_id)
  }, [_id])
  const handleImageChange = (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const patchData = {
        familyName,
        givenName,
        gender,
        day,
        month, 
        year,
        email: userEmail,
        phone,
        avatar: reader.result
      }
     handlePatch(patchData)
    };
    reader.readAsDataURL(file);
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     // Display a custom message or handle other logic
  //     e.preventDefault();
  //     e.returnValue = '';  // Standard for most browsers
  //   };

  //   // Add event listener for beforeunload
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Cleanup on component unmount
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  const getColorByRank = (rank) => {
    if (rank === 'bronze') {
      return '#c1866e'; // Bronze gradient
    } else if (rank === 'silver') {
      return '#8f9193'; // Silver gradient
    } else if (rank === 'gold') {
      return '#ffd74e'; // Gold gradient
    } else if (rank === 'diamond') {
      return '#b1b7f6'; // Diamond gradient
    }
    else if (rank === 'platinum') {
      return '#9fe4dc'; // Diamond gradient
    }
    return 'black'; // Default gradient for other ranks
  };
  const getGradientByRank = (rank) => {
    if (rank === 'bronze') {
      return 'linear-gradient( #c78e73, #c66f44, #c78e73)'; // Bronze gradient
    } else if (rank === 'silver') {
      return 'linear-gradient( #adadad, #ffffff, #eaeaea, #fafafa, #868686)'; // Silver gradient
    } else if (rank === 'gold') {
      return 'linear-gradient( #c0943d, #f9ed7f, #f7f3a9, #fad76f, #ce994d, #b4771e)'; // Gold gradient
    } else if (rank === 'diamond') {
      return 'linear-gradient( #eba7c0, #fbd4cd, #fcf0de, #fffde9, #fff2f2, #ffdaf9, #f2b0fe, #fed7f9, #d2c5fa, #edb4d5)'; // Diamond gradient
    }
    else if (rank === 'platinum') {
      return  'linear-gradient( #9be2da, #bef2ef, #e4fafa, #d0f1f6, #afdaea, #9dcedd, #c2e6e5, #e3ffe8, #bce4e2, #85bfd5, #88d1d6)'; // Diamond gradient
    }
    return 'black'; // Default gradient for other ranks
  };
  const getNameByRank = (rank) => {
    if (rank === 'bronze') {
      return t("login.bronze"); // Bronze gradient
    } else if (rank === 'silver') {
      return t("login.silver"); // Silver gradient
    } else if (rank === 'gold') {
      return t("login.gold"); // Gold gradient
    } else if (rank === 'platinum') {
      return t("login.platinum"); // Diamond gradient
    }
    else if (rank === 'diamond') {
      return t("login.diamond"); // Diamond gradient
    }
    return 'black';
  }
  function RankModal(props) {
    return (
      <>
        <Modal
         {...props}
         size='xl'
         aria-labelledby="contained-modal-title-vcenter"
         centered
         backdrop="static"
         keyboard={false}
         style={{border:'1px', borderColor:'#fff'}}
        >
          <Modal.Header style={{backgroundImage: getGradientByRank(userData?.data?.rank)}}>
          <Modal.Title id="contained-modal-title-vcenter" style={{color: "#000", fontWeight:'bold'}}>
            {t("login.membership")}
          </Modal.Title>
            <Button variant="dark right" onClick={props.onHide}>
            <i className="fa-solid fa-xmark" style={{color:'#fff'}}></i>
          </Button>
          </Modal.Header>
          <Modal.Body style={{height: 'fit-content'}}>
                <div className="container pl-5 pr-5">
                    <div className="row pl-5 pr-5">
                      <div className="col-md-6">
                        <div style={{backgroundImage: getGradientByRank(userData?.data?.rank)}} className="memberShip_container">
                        <div className="d-flex justify-content-between">
                          <div>
                          <div className="memberShip_text1">Azumaya Hotel</div>
                          <div className="memberShip_text2">Membership Card</div>
                          </div>
                          <img src={logo} className="memberShip_logo" />
                        </div>
                        <div className="d-flex justify-content-center">
                          <div className="memberShip_id">{_id.slice(0,5)}</div>
                        </div>
                        <div className="d-flex">
                          <div className="memberShip_text3">Member since</div>
                          <div className="memberShip_text3">{Intl.DateTimeFormat('en-US').format(formatCreatedAt)}</div>
                        </div>
                        <div className="d-flex">
                          <div className="memberShip_text1">{familyName} {givenName}</div>
                        </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mt-md-0">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="rank-title" style={{color: getColorByRank(userData?.data?.rank)}}>{getNameByRank(userData?.data?.rank)}</div>
                            <img src={diamond} className="rank-image"/>
                          </div>
                          <div style={{marginLeft: 20, textAlign:'left'}}>
                            <div style={{fontSize: "2rem"}}>{t('login.benefit')}</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Rebate 3% by Az Coin</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Give 6 Az Coins on birthday</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Free Late Check-out until 14:00 or Free Early Check-in from 13:00</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Free Pick-up or Drop-off 4 times/year</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Upgrade room 3 night/year</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Cash discount for direct deduction for Breakfast, Open Hot Bath and Massage (1000p = 500.000 VND)</div>
                          </div>
                      </div>

                </div>
                </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  function CouponModal(props) {
    return (
      <>
        <Modal
         {...props}
         size='xl'
         aria-labelledby="contained-modal-title-vcenter"
         centered
         backdrop="static"
         keyboard={false}
        >
          <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{color: "#fff", fontWeight:'bold'}}>
            {t("login.coupon")}
          </Modal.Title>
            <Button variant="light right" onClick={props.onHide}>
            <i className="fa-solid fa-xmark"></i>
          </Button>
          </Modal.Header>
          <Modal.Body style={{height: 'fit-content'}}>
                <div className="room__content mt-3">
                  <Tabs
            selectedTabClassName="service__active"
            className="col-md-12 p-0"
            selectedIndex={selectedIndex1}
            onSelect={handleSelect1}
          >
            <TabList className="service__list mt-3">
                <Tab className="service">{t("login.getCoupon")}</Tab>
                <Tab className="service">{t("login.yourCoupon")}</Tab>
                <Tab className="service">{t("login.usedVoucher")}</Tab>
            </TabList>
            <TabPanel>
            <div className="room__title m-1 mt-4">
                    {t("login.getCoupon")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
              <div className="container">
                    <div className="row">
                      {voucher?.data?.map((item, index) => (
                      <div className="col-md-3 mb-4" key={index}>
                        <div className="coupon-container">
                          <div className="d-flex align-items-base">
                            {item.type === "BK" && <img src={hotelbell} style={{width: 50, height: 50}}/> }
                            {item.type === "BF" && <img src={onigiri} style={{width: 50, height: 50}}/> }
                            {item.type === "RT" && <img src={onsen} style={{width: 50, height: 50}}/> }
                            {item.type === "MS" && <img src={massage} style={{width: 50, height: 50}}/> }
                           <h2 style={{backgroundColor: "#fff", color:"#482979", padding: 5, fontWeight:'bold', textTransform:'capitalize'}}>{item.name}</h2>
                          </div>
                           <h4 style={{fontWeight: 'bold'}} className="mt-2">{t('reservation.discountTitle')} {item.discount}%</h4>
                           <div className="d-flex justify-content-between align-items-center w-100">
                           <p className="mt-2" style={{fontWeight: 'bold'}}>{t("login.valid")}: {formatDate(item.expiry)}<br/>{t("login.expired")}: {remainDate(item.expiry)} {remainDate(item.expiry) <=1 ? 'day' : 'days'}</p>
                           <div className="d-flex align-items-center justify-content-center use-btn"  style={{cursor: 'pointer'}} onClick={() => checkVoucher(item.code)}>
                           <div>{Intl.NumberFormat("de-DE").format(item.azCoin)}</div>
                           <img src={azcoin} style={{width: 15, height:15 }} />
                           </div>
                           </div>
                        </div>
                      </div>
                      ))}
                    </div>
              </div>
            </TabPanel>
            <TabPanel>
            <div className="room__title m-1 mt-4">
                    {t("login.yourCoupon")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
              <div className="container">
                    <div className="row">
                      {yourVoucher.map((item, index) => {
                        if(remainDate(item.expired) > 0 ) {
                          return(
                            <div className="col-md-3 mb-4" key={index}>
                              <div className="coupon-container">
                                <div className="d-flex align-items-base">
                                  {item.type === "BK" && <img src={hotelbell} style={{width: 50, height: 50}}/> }
                                  {item.type === "BF" && <img src={onigiri} style={{width: 50, height: 50}}/> }
                                  {item.type === "RT" && <img src={onsen} style={{width: 50, height: 50}}/> }
                                  {item.type === "MS" && <img src={massage} style={{width: 50, height: 50}}/> }
                                <h2 style={{backgroundColor: "#fff", color:"#482979", padding: 5, fontWeight:'bold', textTransform:'capitalize'}}><small>[{item.code}]</small> {item.name}</h2>
                                </div>
                                <h4 style={{fontWeight: 'bold'}} className="mt-2">{t('reservation.discountTitle')} {item.discount}%</h4>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                <p className="mt-2" style={{fontWeight: 'bold'}}>{t("login.valid")}: {formatDate(item.expired)}<br/>{t("login.expired")}: {remainDate(item.expired)} {remainDate(item.expired) <=1 ? 'day' : 'days'}</p>
                                <div style={{cursor: 'pointer'}}onClick={() => navigate(`/${language}/reservation`, {state: {voucher: item.name, discount: item.discount}})} className="use-btn">{t("login.use")}</div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
              </div>
            </TabPanel>
            <TabPanel>
            <div className="room__title m-1 mt-4">
                    {t("login.usedVoucher")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
              <div className="container">
                    <div className="row">
                        {usedVoucher.map((item, index) => (
                        <div className="col-md-3 mb-4" key={index}>
                          <div className="coupon-container">
                          <div className="d-flex align-items-base">
                            {item.type === "BK" && <img src={hotelbell} style={{width: 50, height: 50}}/> }
                            {item.type === "BF" && <img src={onigiri} style={{width: 50, height: 50}}/> }
                            {item.type === "RT" && <img src={onsen} style={{width: 50, height: 50}}/> }
                            {item.type === "MS" && <img src={massage} style={{width: 50, height: 50}}/> }
                           <h2 style={{backgroundColor: "#fff", color:"#482979", padding: 5, fontWeight:'bold', textTransform:'capitalize'}}>{item.name}</h2>
                          </div>
                           <h4 style={{fontWeight: 'bold'}} className="mt-2">{t('reservation.discountTitle')} {item.discount}%</h4>
                           <div className="d-flex justify-content-between align-items-center w-100">
                           <p className="mt-2" style={{fontWeight: 'bold'}}>{language == 'ja' || language == 'ko' ?  `${formatDate(item.useTime)} ${t("login.take")}` : `${t("login.take")} ${formatDate(item.useTime)}`}</p>
                           <div className="d-flex align-items-center justify-content-center">
                           <div style={{fontWeight: 'bold', fontSize: 12}}>-{Intl.NumberFormat('de-DE').format(item.before - item.after)}</div>
                           <img src={azcoin} style={{width: 15, height:15 }} />
                           </div>
                           </div>
                         <div style={{fontWeight:'bold'}}>{item.note}</div>
                        </div>
                      </div>
                      ))}
                    </div>
              </div>
            </TabPanel>
            </Tabs>
                </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  return(
        <>
   <div className="reservation__content" style={{padding: 15}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          </div>
        </div>
      </div>
    </div>
    <div className="container mt-5" >
            <div className="row">
                <div className="col-md-12" ref={colRef}>
                <div className="d-flex align-items-end ">
                  {/* <div style={{textTransform: 'uppercase'}}>
                  {userData?.data?._id.slice(0, 12)}
                  </div> */}
                          <img className="profile-image" src={userImage} alt="Profile" style={{width: 130, height: 130, borderRadius: '50%'}}/>
                          <div style={{textAlign: "left"}}>
                          <p className="ml-3" style={{color: "#000", marginRight: 10, fontSize: '3rem', marginBottom:0}}>{familyName} {givenName} </p>
                          <UserRank />
                          <span style={{
                           color: getColorByRank(userData?.data?.rank),         
                           textTransform: 'capitalize',   
                           fontWeight: 'bold', 
                           fontSize:"1.4rem",  
                          }}             
                          >{getNameByRank(userData?.data?.rank)}</span>
                           <div className="d-flex align-items-center" style={{marginLeft:10}}>
                            <img src={azcoin} style={{width: 30, height:30}} />
                            <div style={{color: "#fece07", fontWeight:'bold', fontSize: '1.8rem'}}>{Intl.NumberFormat('de-DE').format(coin)}</div>
                          </div>
                {/* <div className="col-md-4"> */}
                {/* </div> */}
                          {/* <img src = {gold} className="rank-icon" />
                          <img src = {silver} className="rank-icon" />
                          <img src = {bronze} className="rank-icon" /> */}
                          </div>
                          </div>
                 <label htmlFor="avatar" style={{width: 30, height: 30, borderRadius:100, backgroundColor: '#482879', display:"flex", justifyContent:'center', alignItems:'center', position: 'absolute', top: 100, left:90}}>
                  <i className="fa-solid fa-camera" style={{color: '#fff'}}></i>
                  </label>
                  <input type="file" onChange={handleImageChange} style={{visibility:'hidden'}} id='avatar' />
                  </div>
                  <>
                  {userData?.data?.rank == 'bronze' && (
                    <div>
                      <p style={{ marginLeft: 20, fontSize: 20, marginBottom: 10 }}>
                        Expenses
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30, position: 'relative', width: screenWidth }}>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#c1866e", left: 10 }}>
                          {t('login.bronze')}
                        </span>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#8f9193", right: 40 }}>
                          {t('login.silver')}
                        </span>
                      </div>
                      <div style={{ marginTop: 15, position: 'relative' }}>
                        <div
                          style={{
                            height: 10,
                            width: screenWidth,
                            backgroundColor: '#ccc',
                            borderRadius: 10
                          }}
                        ></div>
                        <div
                          style={{
                            height: 10,
                            width: widthSpendBronze,
                            backgroundColor: '#c1866e',
                            borderRadius: 10,
                            position: 'absolute',
                            top:0
                          }}
                        ></div>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: 4 }}>
                          <div style={{ width: widthSpendBronze - 10}}></div>
                          <i class="fa-solid fa-caret-down" style={{fontSize: '1.4rem', color: '#c1866e'}}></i>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ width: widthSpendBronze - 15 }}></div>
                        <span style={{ fontWeight: 600 }}>{pointBronze}p</span>
                      </div>
                    </div>
                  )}
                  {userData?.data?.rank == 'silver' && (
                    <div>
                      <p style={{ marginLeft: 20, fontSize: 20, marginBottom: 10 }}>
                        Expenses
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30, position: 'relative', width: screenWidth }}>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#8f9193", left: 10 }}>
                          {t('login.silver')}
                        </span>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#ffd74e", right: 40 }}>
                          {t('login.gold')}
                        </span>
                      </div>
                      <div style={{ marginTop: 15, position: 'relative' }}>
                        <div
                          style={{
                            height: 10,
                            width: screenWidth,
                            backgroundColor: '#ccc',
                            borderRadius: 10
                          }}
                        ></div>
                        <div
                          style={{
                            height: 10,
                            width: widthSpendSilver,
                            backgroundColor: '#8f9193',
                            borderRadius: 10,
                            position: 'absolute',
                            top:0
                          }}
                        ></div>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: 4 }}>
                          <div style={{ width: widthSpendSilver - 10}}></div>
                          <i class="fa-solid fa-caret-down" style={{fontSize: '1.4rem', color: '#8f9193'}}></i>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ width: widthSpendSilver - 15 }}></div>
                        <span style={{ fontWeight: 600 }}>{pointSilver}p</span>
                      </div>
                    </div>
                  )}
                  {userData?.data?.rank == 'gold' && (
                    <div>
                      <p style={{ marginLeft: 20, fontSize: 20, marginBottom: 10 }}>
                        Expenses
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30, position: 'relative', width: screenWidth }}>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#ffd74e", left: 10 }}>
                          {t('login.gold')}
                        </span>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#9fe4dc", right: 40 }}>
                          {t('login.platinum')}
                        </span>
                      </div>
                      <div style={{ marginTop: 15, position: 'relative' }}>
                        <div
                          style={{
                            height: 10,
                            width: screenWidth,
                            backgroundColor: '#ccc',
                            borderRadius: 10
                          }}
                        ></div>
                        <div
                          style={{
                            height: 10,
                            width: widthSpendGold,
                            backgroundColor: '#ffd74e',
                            borderRadius: 10,
                            position: 'absolute',
                            top:0
                          }}
                        ></div>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: 4 }}>
                          <div style={{ width: widthSpendGold - 10}}></div>
                          <i class="fa-solid fa-caret-down" style={{fontSize: '1.4rem', color: '#ffd74e'}}></i>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ width: widthSpendGold - 15 }}></div>
                        <span style={{ fontWeight: 600 }}>{pointGold}p</span>
                      </div>
                    </div>
                  )}
                  {userData?.data?.rank == 'platinum' && (
                    <div>
                      <p style={{ marginLeft: 20, fontSize: 20, marginBottom: 10 }}>
                        Expenses
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30, position: 'relative', width: screenWidth }}>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#9fe4dc", left: 10 }}>
                          {t('login.platinum')}
                        </span>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#b1b7f6", right: 40 }}>
                          {t('login.diamond')}
                        </span>
                      </div>
                      <div style={{ marginTop: 15, position: 'relative' }}>
                        <div
                          style={{
                            height: 10,
                            width: screenWidth - 30,
                            backgroundColor: '#ccc',
                            borderRadius: 10
                          }}
                        ></div>
                        <div
                          style={{
                            height: 10,
                            width: widthSpendPlatinum,
                            backgroundColor: '#9fe4dc',
                            borderRadius: 10,
                            position: 'absolute',
                            top:0
                          }}
                        ></div>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: 4 }}>
                          <div style={{ width: widthSpendPlatinum - 10}}></div>
                          <i class="fa-solid fa-caret-down" style={{fontSize: '1.4rem', color: '#9fe4dc'}}></i>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ width: widthSpendPlatinum - 15 }}></div>
                        <span style={{ fontWeight: 600 }}>{pointPlatinum}p</span>
                      </div>
                    </div>
                  )}
                  {userData?.data?.rank == 'diamond' && (
                    <div>
                      <p style={{ marginLeft: 20, fontSize: 20, marginBottom: 10 }}>
                        Expenses
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 30, position: 'relative', width: screenWidth}}>
                        <span style={{ fontWeight: 600, position: 'absolute', color: "#b1b7f6", left: 10 }}>
                          {t('login.diamond')}
                        </span>
                      </div>
                      <div style={{ marginTop: 15, position: 'relative' }}>
                        <div
                          style={{
                            height: 10,
                            width: screenWidth -30,
                            backgroundColor: '#ccc',
                            borderRadius: 10
                          }}
                        ></div>
                        <div
                          style={{
                            height: 10,
                            width: screenWidth -30,
                            backgroundColor: '#b1b7f6',
                            borderRadius: 10,
                            position: 'absolute',
                            top:0
                          }}
                        ></div>
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: 4 }}>
                          <div style={{ width: screenWidth - 42}}></div>
                          <i class="fa-solid fa-caret-down" style={{fontSize: '1.4rem', color: '#b1b7f6'}}></i>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ width: screenWidth - 50}}></div>
                        <span style={{ fontWeight: 600 }}>{pointDiamond}p</span>
                      </div>
                    </div>
                  )}
                  </>
                  <div style={{fontSize: '1.1rem', fontStyle:'italic' }}>* Membership rank will be reset every March 1st (decrease 20% Az Point)</div>
                  <div style={{fontSize: '1.1rem', fontStyle:'italic' }}>* 1 Point = 20.000 VND</div>
                  <RankModal 
                  show = {modalShow4}
                  onHide = {()=> setModalShow4(false)}/>
                   <CouponModal 
                  show = {modalShow1}
                  onHide = {()=> setModalShow1(false)}/>
        <div className="container">
        <button className="base__btn btn--mobile"onClick={() => setModalShow1(true)} style={{width: 180, height: 50, color:"#482979"}}><i class="fa-solid fa-ticket mr-3"></i>{t("login.coupon")}</button>
        <button className="base__btn btn--mobile ml-5" onClick={() => setModalShow4(true)} style={{width: 180, height: 50, color:"#482979"}}><i class="fa-solid fa-address-card mr-3"></i>{t("login.membership")}</button>
          <Tabs
            selectedTabClassName="service__active"
            className="col-md-12 p-0"
            selectedIndex={selectedIndex}
            onSelect={handleSelect}
          >
            <TabList className="service__list mt-3">
                <Tab className="service">{t("login.info")}</Tab>
                {/* <Tab className="service">{t("login.membership")}</Tab> */}
                {/* <Tab className="service">{t("login.coupon")}</Tab>
                <Tab className="service">{t("reservation.exchange")}</Tab> */}
                <Tab className="service">{t("login.book-other")}</Tab>
                <Tab className="service">{t("login.book-history")}</Tab>
                <Tab className="service">{t("login.logout")}</Tab>
            </TabList>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                   {t("login.user-info")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                    <div className="container pl-5 pr-5">
                    <div className="row pl-5 pr-5">
                    <div className="col-md-6">
                        <div className="login_input">
                        <input 
                        ref={inputRef1}
                        onKeyDown={(e) => handleKeyDown(e, inputRef1)}
                        type="text" 
                        className="login_input-field" 
                        value={familyName}
                        onChange={(e) => setFamilyName(e.target.value)}
                        required/>
                        <label className="login_input-label ml-0">{t('reservation.family-name')}</label>
                    </div>
                        </div>
                    <div className="col-md-6 mt-5 mt-md-0">
                        <div className="login_input">
                        <input 
                        ref={(inputRef2)}
                        onKeyDown={(e) => handleKeyDown(e, inputRef2)}
                        type="text" 
                        className="login_input-field" 
                        value={givenName}
                        onChange={(e) => setGivenName(e.target.value)}
                        required/>
                        <label className="login_input-label ml-0">{t('reservation.given-name')}</label>
                    </div>
                    </div>
			        </div>
            <div className="row pl-5 pr-5">
                <div className="col-md-6">
                    <div className="row">
				<div style={{fontSize: '1.2rem', color: !gender ? '#8597a3' : '#482979', textAlign: 'left', padding: 5}}>{t('reservation.gender')}</div>
                        <div className="login_input">
                </div>

				<select
                className={errors.gender ? "col-md-12 form__content validate_failed" : 'col-md-12 form__content'}
				style={{fontSize: '1.2rem'}}
                value={gender}
                onChange={(e) => {
					setGender(e.target.value)
					errors.gender = ''
				}}
                >
				        <option value="" disabled>{t('reservation.gender')}</option>
                {genderValue.map((item, index) => (
                  <option value={item.value} key={index}>{item.value}</option>
                ))}
              </select>
          </div>
              </div>
              <div className="col-md-6">
              <div className="row">
                <div className="login_input">
                <div style={{fontSize: '1.2rem',color: !day || !month || !year ? '#8597a3' : '#482979', textAlign: 'left'}}>{t('reservation.birth-date')}</div>
                </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <DayPicker />
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <MonthPicker />

                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <YearPicker />
                  </div>
                  </div>
                </div>  
                    </div>
                </div>
            <div className="row pl-5 pr-5">
                <div className="col-md-6">
            <div className="login_input">
				<input 
         ref={(inputRef3)}
         onKeyDown={(e) => handleKeyDown(e, inputRef3)}
				type="text" 
				className="login_input-field" 
				value={userEmail}
				onChange={(e) => setUserEmail(e.target.value)}
				required/>
				<label className="login_input-label ml-0">{t('room_hbt1.emailTitle')}</label>
			</div>
            </div>
            <div className="col-md-6 mt-5 mt-md-0">
            <div className="login_input">
				<input 
         ref={(inputRef4)}
         onKeyDown={(e) => handleKeyDown(e, inputRef4)}
				type="text" 
				className="login_input-field" 
				value={phone}
				onChange={(e) => setPhone(e.target.value)}
				required/>
				<label className="login_input-label ml-0">{t('reservation.phone')}</label>
			</div>
          </div>
      </div>
            {/* <div className="row pl-5 pr-5">
                <div className="col-md-6">
                    <div className="login_input">
                        <input 
                        type="password" 
                        className="login_input-field" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required/>
                        <label className="login_input-label ml-0">Password</label>
                    </div>
                </div>
            <div className="col-md-6">
                <div className="login_input">
                    <input 
                    type="password" 
                    className="login_input-field" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required/>
                    <label className="login_input-label ml-0">Confirm Password</label>
                </div>
            </div>
            </div> */}
            <button className="base__btn btn__send" onClick={() => handlePatch(patchData)}>{t('login.save')}</button> 
                    </div>
                    <div className="d-flex justify-content-end w-100 mr-3">
            {/* <button className="base__btn" onClick={() => setModalShow1(true)} style={{backgroundColor: '#ff3131', width: 'fit-content', color: "#fff", fontSize:"bold"}}>{t("login.delete")}</button> */}
                    </div>
            {/* <ConfirmDelete show={modalShow1} onHide={() => setModalShow1(false)} /> */}
                  </div>
                </div>
                </div>
            </TabPanel>      
            {/* <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                   {t("login.membership")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                    <div className="container pl-5 pr-5">
                    <div className="row pl-5 pr-5">
                      <div className="col-md-6">
                        <div style={{backgroundImage: getGradientByRank(userData?.data?.rank)}} className="memberShip_container">
                        <div className="d-flex">
                          <div>
                          <div className="memberShip_text1">Azumaya Hotel</div>
                          <div className="memberShip_text2">Membership Card</div>
                          </div>
                          <img src={logo} className="memberShip_logo" />
                        </div>
                        <div className="d-flex justify-content-center">
                          <div className="memberShip_id">{_id.slice(0,16).match(/.{1,4}/g)?.join(' ')}</div>
                        </div>
                        <div className="d-flex">
                          <div className="memberShip_text3">Member since</div>
                          <div className="memberShip_text3">{Intl.DateTimeFormat('en-US').format(formatCreatedAt)}</div>
                        </div>
                        <div className="d-flex">
                          <div className="memberShip_text1">{familyName} {givenName}</div>
                        </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mt-md-0">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="rank-title" style={{color: getColorByRank(userData?.data?.rank)}}>{t('login.diamond')}</div>
                            <img src={diamond} className="rank-image"/>
                          </div>
                          <div style={{marginLeft: 20, textAlign:'left'}}>
                            <div style={{fontSize: "2rem"}}>{t('login.benefit')}</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Rebate 3% by Az Coin</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Give 6 Az Coins on birthday</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Free Late Check-out until 14:00 or Free Early Check-in from 13:00</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Free Pick-up or Drop-off 4 times/year</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Upgrade room 3 night/year</div>
                            <div style={{fontSize: "1.2rem"}}><i class="fa-solid fa-circle-check" style={{color: '#482979'}}></i> Cash discount for direct deduction for Breakfast, Open Hot Bath and Massage (1000p = 500.000 VND)</div>
                          </div>
                      </div>

                </div>
                </div>
                </div>
                </div>
                </div>
            </TabPanel>                                                                                                                                                                                                                                                    */}
            {/* <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                    {t("login.coupon")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
                  <div className="container">
                    <div className="row">
                      {voucher.map((item, index) => (
                      <div className="col-md-3 mb-4" key={index}>
                        <div className="coupon-container">
                          <div className="d-flex align-items-base">
                            {item.type === "BK" && <img src={hotelbell} style={{width: 50, height: 50}}/> }
                            {item.type === "BF" && <img src={onigiri} style={{width: 50, height: 50}}/> }
                            {item.type === "RT" && <img src={onsen} style={{width: 50, height: 50}}/> }
                            {item.type === "MS" && <img src={massage} style={{width: 50, height: 50}}/> }
                           <h2 style={{backgroundColor: "#fff", color:"#482979", padding: 5, fontWeight:'bold', textTransform:'capitalize'}}>{item.name}</h2>
                          </div>
                           <h4 className="mt-2">{t('reservation.discountTitle')} {item.discount}</h4>
                           <div className="d-flex justify-content-between align-items-center w-100">
                           <p className="mt-2" style={{fontWeight: 'bold'}}>{t("login.valid")}: {formatDate(item.expired)}<br/>{t("login.expired")}: {remainDate(item.expired)} {remainDate(item.expired) <=1 ? 'day' : 'days'}</p>
                           <div onClick={() => navigate(`/${language}/reservation`, {state: {voucher: item.name, discount: item.discount}})} className="use-btn">{t("login.use")}</div>
                           </div>
                        </div>
                      </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel> */}
            {/* <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                    {t("reservation.exchange")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
                  <div className="container">
                    <div className="row">
                     
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel> */}
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                  {t("login.book-other")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
                  {userData ? (
                    <>
                  <div className="room__container ml-md-0 ml-3">
                    <div className="d-flex">
                    <div className="guest-card mr-3">
                    {listGuest.map((item, index) => (
                    <div className="book-container d-flex justify-content-between mb-3" key={index}>
                      <div className="d-flex align-items-center">
                      <img src ={profilePicture} style={{width: 50, height: 50}}/>
                      <div className="d-flex flex-column justify-content-start ml-3">
                        <div className="other-name" style={{textAlign:'start', fontWeight:'bold'}}>{item.gender} {item.familyName} {item.givenName}</div>
                        <small style={{textAlign:'start'}}>{t("room_hbt1.emailTitle")}: {item.email}</small>
                        <small style={{textAlign:'start'}}>{t("reservation.birth-date")}: {item.month} - {item.day} - {item.year}</small>
                      </div>
                      </div>
                      <div className="d-flex">
                    <div className="d-flex align-items-center ml-1">
                      <button className="edit-btn" onClick={() => {
                        setIndex(index)
                        setModalShow2(true)}
                        }><i className="fa-solid fa-pen-to-square"></i></button>
                    </div>
                    <div className="d-flex align-items-center ml-1 mr-1">
                      <button className="edit-btn" 
                        onClick={() => {
                        setIndex(index)
                        handleClick(index)
                        }}>
                        <i className="fa-solid fa-bell-concierge"></i></button>
                    </div>
                    <div className="d-flex align-items-center mr-1">
                      <button className="edit-btn" 
                        onClick={() => {
                        setIndex(index)
                        deleteGuest({index: index})
                        }}>
                        <i className="fa-solid fa-xmark"></i></button>
                    </div>
                      </div>
                    </div>
              ))}
                    </div>
                    </div>
                    </div>
                    <EditGuestInfo show={modalShow2} onHide={() => setModalShow2(false)} />
                    <div className="d-flex justify-content-center mt-3 mb-3 w-100">
                      <button className="edit-btn" onClick={() => setModalShow(true)}><i className="fa-solid fa-user-plus"></i></button>
                      <InputGuestInfo show={modalShow} onHide={() => setModalShow(false)} />
                    </div>
                    </>
                  ): ''}
                  </div>
                </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                    {t("login.book-history")}
                    <img
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                      className="style-line"
                    />
                    <div className="container">
                      <div className="row">
                      {listReservation.map((item, index) => {
                         const dateStr = item.createdAt
                         const date = new Date(dateStr);
                         const startDate = new Date(item.startDate)
                         const endDate = new Date(item.endDate)
                         let dayCount = dayjs(endDate).diff(dayjs(startDate), 'day')
                         const formattedDate = date.toLocaleDateString('en-GB');
                         const formattedTime = date.toLocaleTimeString('en-GB');
                        return(
                        <div className="col-md-4 pb-3" key={index}>
                          <div className="d-flex">
                            <div className="d-flex flex-column justify-content-center align-items-center history-container pl-0 p-2">
                            <div className="history-date">{formattedDate}</div>
                            <div className="history-hour">{formattedTime}</div>
                            </div>
                           <div className="history-container pr-0 pr-md-2 " style={{width: 'fit-content'}}>
                            <div className="d-flex">
                              <div className="d-flex">
                              <div className="pr-2"style={{width: 100}}>{t("login.bookingId")}:</div>
                              <div className="history-text">{item._id}</div>
                              </div>
                              {/* <div className="booking-status-completed pr-3">Completed</div> */}
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.branch")}:</div>
                            <div className="history-text">{item.branchNameWithLang[language]}</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("room_hbt1.type")}:</div>
                            <div className="history-text">{item.selectedRoom}</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.schedule")}: </div>
                            <div className="history-text">{dayCount} {t('reservation.night')}</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.guest-amount")}:</div>
                            <div className="history-text">{item.roomAmount}</div>
                            </div>
                           </div>
                        </div>
                        </div>
                      )})}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title pre-line">
                  {t("login.thankyou")}
                    <img
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                      className="style-line"
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                        <button className="base__btn" onClick={handleLogout} style={{backgroundColor: '#ff3131', width: 200, color: "#fff", fontSize:"bold"}}>{t("login.logout")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
      </div>   
            </div>
        </div>  
            </>
        
    )
}