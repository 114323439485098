import Cookie from "js-cookie";
import { getCookies } from "./getCookies";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Privacy() {
  const { t } = useTranslation();
  const definitions = t("privacy.definitionContent2", { returnObjects: true });
  const collect = t("privacy.collectTitle", { returnObjects: true });
  const usage = t("privacy.usageContent", { returnObjects: true });
  const social = t("privacy.socialMedia", { returnObjects: true });
  const social2 = t("privacy.socialMedia2", { returnObjects: true });
  const tracking = t("privacy.trackingContent", { returnObjects: true });
  const cookies = t("privacy.cookiesContent", { returnObjects: true });
  const personal = t("privacy.personalContent", { returnObjects: true });
  const personal2 = t("privacy.personalContent2", { returnObjects: true });
  const retention = t("privacy.retentionContent", { returnObjects: true });
  const transfer = t("privacy.transferContent", { returnObjects: true });
  const deleteContent = t("privacy.deleteContent", { returnObjects: true });
  const legal = t("privacy.legalContent2", { returnObjects: true });
  const children = t("privacy.childrenContent", { returnObjects: true });
  const link = t("privacy.linkContent", { returnObjects: true });
  const changes = t("privacy.changesContent", { returnObjects: true });
  return (
    <>
      <div className="reservation__content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>{t("privacy.privacy")}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="policy-container">
            <div className="container">
              <h1>
                <strong>{t("privacy.privacy")}</strong>
              </h1>
              <p>{t("privacy.lastUpdate")} 14-01-2025</p>
              <p>{t("privacy.introduce")}</p>
              <p>{t("privacy.introduce2")}</p>
              <h2>
                <strong>{t("privacy.i&d")}</strong>
              </h2>
              <h3>
                <strong>{t("privacy.interpretation")}</strong>
              </h3>
              <p>{t("privacy.iContent")}</p>
              <h3>
                <strong>{t("privacy.definition")}</strong>
              </h3>
              <p>{t("privacy.definitionContent")}</p>
              <ul>
                {definitions.map((item, index) => (
                  <li key={index}>
                    <p>
                      <strong>{item.name}</strong> {item.content}
                    </p>
                  </li>
                ))}
              </ul>
              <h2>
                <strong>{t("privacy.collect")}</strong>
              </h2>
              <h3>
                <strong>{t("privacy.type")}</strong>
              </h3>
              <h4>
                <strong>{t("privacy.personal")}</strong>
              </h4>
              <p>{t("privacy.introduce3")}</p>
              <ul>
                {collect.map((item, index) => (
                  <li key={index}>
                    <p>{item.name}</p>
                  </li>
                ))}
              </ul>
              <h4>
                <strong>{t("privacy.usage")}</strong>
              </h4>
              <p>{t("privacy.introduce4")}</p>
              {usage.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h4>
                <strong>{t("privacy.thirdparty")}</strong>
              </h4>
              <p>{t("privacy.introduce5")}</p>
              <ul>
                {social.map((item, index) => (
                  <li key={index}>
                    <p>{item.name}</p>
                  </li>
                ))}
              </ul>
              {social2.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h4>
                <strong>{t("privacy.tracking")}</strong>
              </h4>
              <p>{t("privacy.introduce6")}</p>
              <ul>
                {tracking.map((item, index) => (
                  <li key={index}>
                    <p>
                      <strong>{item.name}</strong> {item.content}
                    </p>
                  </li>
                ))}
              </ul>
              <p>
                {t("privacy.cookies")}{" "}
                <a
                  href="https://azumayavietnam.com/en/cookie-policy"
                  target="_blank"
                >
                  {t("privacy.privacywebsite")}
                </a>
              </p>
              <p>{t("privacy.introduce7")}</p>
              <ul>
                {cookies.map((item, index) => (
                  <li key={index}>
                    <p>
                      <strong>{item.name}</strong>
                    </p>
                    <p>{item.type}</p>
                    <p>{item.admin}</p>
                    <p>{item.content}</p>
                  </li>
                ))}
              </ul>
              <p>{t("privacy.introduce8")}</p>
              <h3>
                <strong>{t("privacy.personaldata")}</strong>
              </h3>
              <p>{t("privacy.introduce9")}</p>
              <ul>
                {personal.map((item, index) => (
                  <li key={index}>
                    <p>
                      <strong>{item.name}</strong> {item.content}
                    </p>
                  </li>
                ))}
              </ul>
              <p>{t("privacy.introduce10")}</p>
              <ul>
                <p>
                  {personal2.map((item, index) => (
                    <li key={index}>
                      <strong>{item.name}</strong> {item.content}
                    </li>
                  ))}
                </p>
              </ul>
              <h3>
                <strong>{t("privacy.retention")}</strong>
              </h3>
              {retention.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h3>
                <strong>{t("privacy.transfer")}</strong>
              </h3>
              {transfer.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h3>
                <strong>{t("privacy.delete")}</strong>
              </h3>
              {deleteContent.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h3>
                <strong>{t("privacy.disclosure")}</strong>
              </h3>
              <h4>
                <strong>{t("privacy.transaction")}</strong>
              </h4>
              <p>{t("privacy.transactionContent")}</p>
              <h4>
                <strong>{t("privacy.law")}</strong>
              </h4>
              <p>{t("privacy.lawContent")}</p>
              <h4>
                <strong>{t("privacy.legal")}</strong>
              </h4>
              <p>{t("privacy.legalContent")}</p>
              <ul>
                <p>
                  {legal.map((item, index) => (
                    <li key={index}>{item.content}</li>
                  ))}
                </p>
              </ul>
              <h3>
                <strong>{t("privacy.security")}</strong>
              </h3>
              <p>{t("privacy.securityContent")}</p>
              <h2>
                <strong>{t("privacy.childrenPrivacy")}</strong>
              </h2>
              {children.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h2>
                <strong>{t("privacy.link")}</strong>
              </h2>
              {link.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h2>
                <strong>{t("privacy.changes")}</strong>
              </h2>
              {changes.map((item, index) => (
                <p key={index}>{item.content}</p>
              ))}
              <h2>
                <strong>{t("privacy.contact")}</strong>
              </h2>
              <p>{t("privacy.contactContent")}</p>
              <ul>
                <li>
                  <p>{t("privacy.email")}</p>
                </li>
                <li>
                  <p>{t("privacy.phone")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
