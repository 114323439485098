import React from 'react';
import { Routes, Route, useParams, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Home from "./container/Home/Home";
import Feature from "./container/Feature/Feature";
import Policies from "./container/Policies/Policies";
import Contract from "./container/Contract/Contract";
import Reservation from './container/Reservation/Reservation';
import NewsList from "./components/Home/NewsList";
import News from "./components/Home/News";
import NewsByDate from "./components/Home/NewsByDate";
import SignUp from "./container/Login/SignUp";
import HotelHN from "./components/AreaDetail/hotel-hn";
import HotelHCM from "./components/AreaDetail/hotel-hcm";
import HotelDN from "./components/AreaDetail/hotel-dn";
import HotelHP from "./components/AreaDetail/hotel-hp";
import HBT1RoomDetail from "./components/BranchDetail/hai-ba-trung-detail/room";
import KM2RoomDetail from "./components/BranchDetail/kim-ma-2-detail/room";
import KM3RoomDetail from "./components/BranchDetail/kim-ma-3-detail/room";
import LLRoomDetail from "./components/BranchDetail/linh-lang-detail/room";
import TVL1RoomDetail from "./components/BranchDetail/thai-van-lung-1-detail/room";
import TVL2RoomDetail from "./components/BranchDetail/thai-van-lung-2-detail/room";
import LTTRoomDetail from "./components/BranchDetail/le-thanh-ton-detail/room";
import AnnexRoomDetail from "./components/BranchDetail/annex-detail/room";
import DNRoomDetail from "./components/BranchDetail/da-nang/room";
import HPRoomDetail from "./components/BranchDetail/hai-phong/room";
import ThankYou from './container/Reservation/ThankYou';
import VietnamService from "./container/Service/Service"
import ErrorPage from './container/Units/ErrorPage';
import Reservation_backup from './container/Reservation/Reservation_backup'
import ThankYouService from './container/Service/ThankYou';
import ThankYouContract from './container/Contract/ThankYou';
import Cookies from "js-cookie";
import LineCallback from './LineCallback';
import Setting from './container/Login/Setting';
import Greeting from './container/Login/Greeting';
import Bowser from 'bowser';
import HomeTest from './container/Home/HomeTest'
import CookieConsent from './components/Cookies/CookiesConsent';
import CookiePolicy from './components/Cookies/CookiePolicy';
import ResetPassword from './container/Login/ResetPassword';
import ForgotPassword from './container/Login/ForgotPassword.jsx';
import Login from "./container/Login/Login.jsx";
import Active from './container/Login/ActivePage.jsx';
import Test from './Test/Test.jsx';
import Privacy from './components/Cookies/Privacy.jsx';
import Recruitment from './container/Recruitment/Recruitment.jsx';
function NewRouter(props) {
    const {news} = props;
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const defaultLanguage = Cookies.get('selectedLanguage') || i18n.language || 'en';
    const [deviceType, setDeviceType] = useState('');
    const [userEmail, setUserEmail] = useState('')
    const [userData, setUserData] = useState()
   
      useEffect(() => {
        const handleResize = () => {
          const width = window.innerWidth;
          if (width >= 1024) {
            setDeviceType('pc');
          } else if (width >= 768) {
            setDeviceType('tablet');
          } else {
            setDeviceType('phone');
          }
        };
        handleResize(); 
        window.addEventListener('resize', handleResize); 
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      useEffect(() => {
        const supportedLanguages = ['en', 'ja', 'vi', 'ko'];
        const pathSegments = location.pathname.split('/');
        const languageFromPath = pathSegments[1];
    
        if (supportedLanguages.includes(languageFromPath)) {
          if (i18n.language !== languageFromPath) {
            i18n.changeLanguage(languageFromPath);
          }
        } else {
          const defaultLanguage = Cookies.get('i18next') || navigator.language.split('-')[0];
          const languageToUse = supportedLanguages.includes(defaultLanguage) ? defaultLanguage : 'en';
          navigate(`/${languageToUse}${location.pathname}`, { replace: true });
        }
      }, [location.pathname, i18n, navigate]);

      const [browser, setBrowser] = useState('');
      useEffect(() => {
        const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
        setBrowser(browserName);
      }, []);

      const getData = async () => {
          try {
            const response = await fetch(`/api/user/wIzo9Vlr-GIpz-cuQ9-R1Q8-9I5KIyEFShl`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            
            });
            
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            setUserData(responseData)   
          } 
          catch (error) {
            console.error('Error:', error);
          }
      }

      const refreshToken = async ()  =>{
        try {
          const response = await fetch(`/api/user/a5cf06a7-ae4e-4c21-b73e-71b212b46c99`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            // body: JSON.stringify(data)
            
          });
            //   const res = await axios.post('/user/refresh_token', null);

        } catch (error) {
          console.error('Error:', error);
        }
      }

      useEffect(() => {
        const getEmail = () => {
          const savedUserData = localStorage.getItem('userData');
          if (savedUserData) {
            setUserEmail(JSON.parse(savedUserData));
          }
        };
        refreshToken()
        getEmail();
      }, []); 
    
      useEffect(() => {
        if (userEmail) {
          getData()
          // take token
          refreshToken()
        }
      }, [userEmail]); 
    return (
        <section>
            <Routes>
                <Route path="/" element={<Navigate to={`/${i18n.language}/`} replace />} />
                <Route path= "/:lang/" element={<Home deviceType={deviceType} browser={browser} />} />
                {/* <Route path = "/:lang/" element={<HomeTest />} /> */}
                <Route path = "/:lang/service/" element={<VietnamService />} />
                <Route path = "/:lang/q&a/" element={<Policies />} />
                <Route path = "/:lang/reservation/" element={<Reservation deviceType={deviceType} browser={browser}/>} />
                <Route path = "/:lang/feature/" element={<Feature/>} />
                <Route path = "/:lang/feature/:featureID/" element={<Feature/>} />
                <Route path = "/:lang/breakfast/" element={<VietnamService deviceType={deviceType} tab={1}/>} />
                <Route path = "/:lang/rotenburo/" element={<VietnamService deviceType={deviceType} tab={2}/>} />
                <Route path = "/:lang/massage/" element={<VietnamService deviceType={deviceType} tab={3}/>} />
                <Route path = "/:lang/contract/" element={<Contract />} />
                <Route path = "/:lang/thank-you/:selectedCity/" element={<ThankYou />} />
                <Route path = "/:lang/massage/thank-you/:selectedCity/" element={<ThankYouService />} />
                <Route path = "/:lang/contract/thank-you/:selectedCity/" element={<ThankYouContract />} />
                <Route path = "/:lang/news/:title/" element={<News/>} />
                <Route path = "/:lang/news/" element={<NewsList/>} />
                <Route path = "/:lang/news-by-date/:date/" element={<NewsByDate/>} />
                <Route path = "/:lang/hotel-hn/" element={<HotelHN/>} />
                <Route path = "/:lang/hotel-hcm/" element={<HotelHCM />} />
                <Route path = "/:lang/hotel-dn/" element={<HotelDN/>} />
                <Route path = "/:lang/hotel-hp/" element={<HotelHP />} />
                <Route path = "/:lang/hai-ba-trung-detail/room/" element={<HBT1RoomDetail />} />
                <Route path = "/:lang/kim-ma-2-detail/room/" element={<KM2RoomDetail />} />
                <Route path = "/:lang/kim-ma-3-detail/room/" element={<KM3RoomDetail />} />
                <Route path = "/:lang/linh-lang-detail/room/" element={<LLRoomDetail />} />
                <Route path = "/:lang/thai-van-lung-1-detail/room/" element={<TVL1RoomDetail />} />
                <Route path = "/:lang/thai-van-lung-2-detail/room/" element={<TVL2RoomDetail />} />
                <Route path = "/:lang/le-thanh-ton-detail/room/" element={<LTTRoomDetail />} />
                <Route path = "/:lang/annex-detail/room/" element={<AnnexRoomDetail />} />
                <Route path = "/:lang/da-nang/room/" element={<DNRoomDetail />} />
                <Route path = "/:lang/hai-phong/room/" element={<HPRoomDetail />} />
                <Route path='/:lang/setting/' element={<Setting />} />
                {/* <Route path = '*' element={<ErrorPage />} /> */}
                <Route path = '/:lang/dev-test' element={<Reservation_backup deviceType={deviceType} browser={browser}/>} />
                {/* <Route path = '/:lang/daylachodetest/' element={<ProductList />} /> */}
                <Route path = '/:lang/callback' element={<LineCallback />} />
                <Route path ='/:lang/cookie-policy/' element={<CookiePolicy />} />
                <Route path ='/:lang/privacy/' element={<Privacy />} />
                <Route path = '/:lang/reset_password/:token' element={<ResetPassword />} />
                <Route path = '/:lang/register' element={<SignUp />} />
                <Route path = '/:lang/login' element={<Login />} />
                <Route path='/:lang/forgot-password' element={<ForgotPassword />} />
                <Route path='/:lang/greeting' element={<Greeting />} />
                <Route path='/:lang/recruitment' element={<Recruitment />} />
                <Route path='/:lang/active/:token' element={<Active />} />                                  
                <Route path ='/:lang/test' element={<Test />} />
              </Routes>
        </section>
    );
}
export default NewRouter;