import Booking from "../../container/Units/Booking"
import BookingRoom from "../../container/BookingRoom/BookingRoom";
import { useState } from "react";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import {format, parse} from "date-fns"
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import HelmetLayout from "../HelmetLayout/HelmetLayout";
import ScrollAnimation from 'react-animate-on-scroll';
export default function HotelHCM(){
    const { t, i18n } = useTranslation();
    const news = t("news.source", { returnObjects: true });
    const language = i18n.language || 'en'
    const homeNews = news.slice(0,5)
    const HCMDetail = t("hcm-branch.branch", { returnObjects: true });
    const hcmArea = t("hcm-branch.slider", {returnObjects: true });
    const resetCache = Math.floor(Math.random() * 1e20)
    const AutoPlaySlider =  withAutoplay(AwesomeSlider)
    const [selectedCity, setSelectedCity] = useState('hotel-hcm');
    const [selectedBranch, setSelectedBranch] = useState('le-thanh-ton-detail');
    const a = t("header.hcm")
    const b = t("header.title2")
    const c = a + " | "+ b
    return(
        <div>
           <HelmetLayout title={c} />
            <div className="area_header" >
              <div className="overlay"></div>
              <AutoPlaySlider
              animation = "scaleOutAnimation"
              mobileTouch
              infinite
              play
              interval = {5000}>
          {hcmArea.map((item, index)=>(
              <div data-src={item.image} key={index}>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                  <h1>{item.desc}</h1>
                  </div>
                  </div>
                  </div>
                  </div>
          ))}
      </AutoPlaySlider>
            </div>
            <div className = 'is-sticky'>
                <BookingRoom 
                selectedCity={selectedCity}
                selectedBranch={selectedBranch}
                setSelectedCity={setSelectedCity}
                setSelectedBranch={setSelectedBranch}
                />
            </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
            <div className="re__breadcrumb">
                        <ul className="breadcrumb__list">
                                   <li className = "breadcrumb__item">
                                       <a href={`/${language}/`}>
                                           <i className="fa-solid fa-house"></i>
                                       </a>
                                   </li>
                                   <li className = "breadcrumb__item">
                                       /
                                   </li>
                                   <li className = "breadcrumb__item">
                                        <Link to = {`/${language}/hotel-hcm/`} className ="breadcrumb__title">{t("header.hcm")}</Link>
                                   </li>
                       </ul>
                    </div>
                    </div>
                </div>
            </div>
            <div className="branch__container">
                <div className="container">
                    {HCMDetail.map((item, index)=>(
                      <ScrollAnimation animateIn="fadeInUp" animateOnce key={index}>
                <div className="feature__type-item">
                    <div className="card">
                    <div className="row p-0">
                        <div className="col-md-5">
                        <div className = "brand-img" style={{backgroundImage: `url(${item.image}?v=${resetCache})`}} ></div>
                        </div>
                        <div className="col-md-7">
                            <div className="card-body">
                                <div className="card-title">
                                <h2>{item.name}</h2>
                                </div>
                                <div className="card-text">
                                <p>{item.desc}</p>
                                </div>
                                </div>
                                <div className="btn-holder">
                                <div className="btn__detail control-position">
                                    <Link to = {`/${language}${item.link}`}>{t("hcm-branch.btn-detail")}</Link>
                                </div>
                                </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    </ScrollAnimation>
                    ))}
            </div>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div className="content__news">
          <div className="container">
            <div className="row align-item-center justify-content-center">
              <div className="col-md-12">
                <h2 className="content__news-title" style={{fontWeight:'bold'}}>{t("home.news_title")}</h2>
                <div className="d-flex align-item-center justify-content-md-center">
                <div className="content__news-list">
                {news
                .filter(item => item.hcm == true || item.allBranch == true)
                .slice(0,4)
                .map((article, index) => {
               const parsedDate = parse(article.date, 'yyyy-MM-dd', new Date()); 
               const formattedDate = format(parsedDate, 'MMM do yyyy')
               const [all, month, day, suffix, year] = formattedDate.match(/(\w+) (\d+)(\w+) (\d+)/);
               const encodedTitle = encodeURIComponent(article.title);
               const modifiedTitle = encodedTitle.replace(/%20/g, '-');
              if (article.allBranch == true)
               {
                return (
                  <div className="d-flex" key={index}>
                    <div className="mt-1 news_date-container">
                      <div className="news_box1">
                        <div className="news_time-home">
                        <div className="month">{month}</div>
                        <div className="day pl-2">{day}</div>
                        <sub className="suffix pt-2">{suffix}</sub>
                        <div className="year pl-2">{year}</div>
                        </div>
                      </div>
                    </div>
                    <div className="news_branch-container">
                          <div className="allbranch allbranch_home">{t("header.vietnam")}</div>
                    </div>
                    <div className="mt-2 news_title-container">
                      <div >
                        <Link
                        key={article.id}
                          className="news_title news_homeTitle"
                          to={`/${language}/news/${modifiedTitle}`}
                        >
                          <div className="article_title">{article.news_title}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              } else if (article.hcm == true) {
                return (
                  <div className="d-flex" key={index}>
                    <div className="mt-1 news_date-container" >
                      <div className="news_box1">
                        <div className="news_time-home">
                          <div className="month">{month}</div>
                        <div className="day pl-2">{day}</div>
                        <sub className="suffix pt-2">{suffix}</sub>
                        <div className="year pl-2">{year}</div>
                        </div>
                      </div>
                    </div>
                        <div className="news_branch-container" >
                          <div className="hochiminh hochiminh_home">{t("header.hcm")}</div>
                        </div>
                    <div className="mt-2 news_title-container">
                      <div key={article.id}>
                        <Link
                          className="news_title news_homeTitle"
                          to={`/${language}/news/${modifiedTitle}`}
                        >
                          <div className="article_title">{article.news_title}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              } else if (article.az == true) {
                return (
                  <div className="d-flex" key={index}>
                    <div className="mt-1 news_date-container" >
                      <div className="news_box1">
                        <div className="news_time-home">
                          <div className="month">{month}</div>
                        <div className="day pl-2">{day}</div>
                        <sub className="suffix pt-2">{suffix}</sub>
                        <div className="year pl-2">{year}</div>
                        </div>
                      </div>
                    </div>
                        <div className="news_branch-container" >
                          <div className="azumaya azumaya_home">{t("header.az")}</div>
                        </div>
                    <div className="mt-2 news_title-container">
                      <div key={article.id}>
                        <Link
                          className="news_title news_homeTitle"
                          to={`/${language}/news/${modifiedTitle}`}
                        >
                          <div className="article_title">{article.news_title}</div>
                        </Link>
                      </div>
                    </div>
                    </div>
                );
              }
            })}
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        </ScrollAnimation>
        </div>
        </div> 
    )
}