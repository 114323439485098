import { Link, useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext, useRef } from "react";
import Cookies from "js-cookie";
import Avatar from 'react-avatar';
import { UserContext } from "../../userProvider";
import profilePicture from "../../image/profile-picture.png"
import Collapsible from "react-collapsible";
import azcoin from "../../image/azcoin.png"
import Spinner from 'react-bootstrap/Spinner';
function Header() {
  const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
  let language = i18n.language || 'en'
  const { userImage, logout } = useContext(UserContext);
  const [userEmail, setUserEmail] = useState('')
  const [userData, setUserData] = useState()
  const [show, setShow] = useState(false)
  const itemRef = useRef(null); 
  const [notification, setNotification] = useState()
  const [notificationCount, setNotificationCount] = useState()
  const [trigger, setTrigger] = useState(false);

  const toggleShow = () => {
    setShow((prev) => !prev)
    setVisibleCount(10)
    // let skip = 0
    // let limit = 10
    // loadMore(skip, limit)
  }
  const handleUnFocus = (e) => {
    if (itemRef.current && !itemRef.current.contains(e.target)) {
      setShow(false);
    }
  }
  useEffect(() => {
    // Add event listener for clicks outside the component
    document.addEventListener('mousedown', handleUnFocus);
    return () => {
      // Cleanup event listener
      document.removeEventListener('mousedown', handleUnFocus);
    };
  }, []);

  const getNotification = async () => {
    try {
      const response = await fetch(`/api/notification/77a173b2-5844-43ad-a866-15cef4872565`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      });
      
      if (!response.ok) {
      throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      setNotificationCount(responseData)   
    } 
    catch (error) {
      console.error('Error:', error);
    }
}
  const changeLanguage = (language) => {
  i18n.changeLanguage(language, () => {
    Cookies.set('selectedLanguage', language, { expires: 365 });
    const currentPath = location.pathname;
    const newPath = currentPath.replace(/^\/(en|ja|vi|ko)/, `/${language}`);
    if (currentPath !== newPath) {
      navigate(newPath, { replace: true });
    }
  });
};
  useEffect(() => {
    const savedLang = Cookies.get('selectedLanguage');
    if(savedLang && i18n.language !== savedLang){
      i18n.changeLanguage(savedLang)
      }
  })
  const feature = t("feature.feature_item", { returnObjects: true });
  const hcm = t("hcm-branch.branch", {returnObjects:true})
  const hn = t("hn-branch.branch", {returnObjects:true})
  const dn = t("dn-branch.branch", {returnObjects:true})
  const hp = t("hp-branch.branch", {returnObjects:true})
  const service = t("service.service_name", {returnObjects:true})
  const [isOpen, setIsOpen] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const isProduct = process.env.NODE_ENV === 'production'
    useEffect(() => {
      const handleScroll = () => {
        const position = window.scrollY;
        if (position > 100) {
          setBackgroundColor('#482979'); 
        } else {
          setBackgroundColor('transparent'); 
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  const toggleHeader = () => {
      setIsOpen(!isOpen)
  }
  useEffect(()=>{
    if(isOpen) {
      document.body.style.position= "fixed";
    }else{
      document.body.style.position = "";
    }
  })
;
  const HeaderMobile = () => {
    return(
    <ul className={`header__mobile-navbar-list ${isOpen ? 'open' :''}`}>
          <button
            onClick={()=> setIsOpen(false)}
            className="header__mobile-navbar-closed"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          {!isProduct && (
          <li className="header__mobile-navbar-item">
          {userData ? (
                          <div className='header__mobile-navbar-link' style={{padding: 20}}>
                          <div className="profile-container d-flex align-items-center">
                          <img className="profile-image" src={userImage || userData.data.avatar || profilePicture} alt="Profile" style={{width: 30, height: 30,  marginRight: 10, borderRadius: '50%'}}/>
                          <p className="mb-0" style={{color: "#000"}}>{userData.data.name}</p>
                          </div>
                          <div className="d-flex align-items-center mt-3">
                          <Link to = {`/${language}/setting`} onClick={() => setIsOpen(false)} className="profile-btn"><i className="fa-solid fa-gear" style={{color: '#fff'}}></i></Link>
                          <div>Setting</div>
                          </div>
                          <div className="d-flex align-items-center mt-3">
                          <Link to = '' onClick={handleLogout}
                          className="profile-btn" style={{backgroundColor: '#fff'}}><i className="fa-solid fa-right-from-bracket"style={{color: '#482979', fontSize:'2.2rem'}}></i></Link>
                          <div>Log out</div>
                          </div>
                          </div>
                        ) :
          <li className="header__mobile-navbar-item" >
            <Link
              onClick={()=> setIsOpen(false)}
              Link
              to={`/${language}/login`}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-right-to-bracket"></i> {t("login.login")}
            </Link>
          </li>
              }
          </li>
          )}
          <li className="header__mobile-navbar-item">
            <Link
              onClick={()=> setIsOpen(false)}
              to={`/${language}/`}
              className="header__mobile-navbar-link mr-2"
            >
              <i className="fa-solid fa-house purple"></i> {t("header.home")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              onClick={()=> setIsOpen(false)}
              to={`/${language}/news/`}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-newspaper purple mr-2"></i> {t("header.news")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
          <Collapsible className='mt-3' trigger={<div style={{padding: 20, fontSize: '1.4rem', fontWeight: 100}}><img src ="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/vn.svg" style={{width:17, height: 12}}/> {t("header.vietnam")}</div>} width={17} height={12}>
          <ul>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-hn/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-hotel purple"></i> {t("header.hn")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-hcm/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-hotel purple"></i> {t("header.hcm")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-dn/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-hotel purple"></i> {t("header.dn")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item" style={{borderBottom: 'none'}}>
            <Link
              to={`/${language}/hotel-hp/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-hotel purple"></i> {t("header.hp")}
            </Link>
          </li>
          </ul>
           </Collapsible>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to="http://azumayacambodia.com"
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
             <img src ="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kh.svg" style={{width:17, height: 12}}/> {t("header.cambodia")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/reservation/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-bell-concierge purple mr-2"></i> {t("header.reservation")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/feature/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-star purple mr-2"></i> {t("header.feature")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
         <Collapsible className='mt-3' trigger={<div style={{padding: 20, fontSize: '1.4rem', fontWeight: 100}}><img src ="https://cdn-icons-png.flaticon.com/512/7096/7096487.png" style={{width:17, height: 12}}/> {t("header.service")}</div>} marginRight={5} width={17} height={17} > 
              <ul> 
              <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/breakfast/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link d-flex align-items-center"
            >
             <img src="https://cdn-icons-png.flaticon.com/512/3911/3911444.png" style={{width:20, height: 15, marginRight: 5}}/> {t("header.breakfast")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/rotenburo/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link d-flex align-items-center"
            >
              <img src="https://cdn-icons-png.flaticon.com/512/7802/7802322.png" style={{width:20, height: 15, marginRight: 5}}/> {t("header.roten")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item" style={{borderBottom:'none'}}> 
            <Link
              to={`/${language}/massage/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link d-flex align-items-center"
            >
             <img src="https://cdn-icons-png.flaticon.com/512/8119/8119611.png" style={{width:20, height: 20, marginRight: 5}}/>  {t("header.massage")}
            </Link>
          </li>
              </ul>
          </Collapsible>
          </li>
          {/* <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/service/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.service")}
            </Link>
          </li> */}
         
          {/* <li className="header__mobile-navbar-item">
            <Link
              to="/Massage"
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.massage")}
            </Link>
          </li> */}
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/contract/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
             <i className="fa-solid fa-handshake purple mr-2"></i> {t("header.contract")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/q&a`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-clipboard-check purple mr-4" /> {t("header.policies")}
            </Link>
          </li>
          {language === 'vi' && (
            <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/recruitment`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              <i className="fa-solid fa-users purple mr-4" /> {t("header.recruitment")}
            </Link>
          </li>
                )}
        </ul>
    )
  }

  const [active, setActive] = useState(false)

  const handleActive = () => {
    setActive(!active)
  }
 
  const handleLogout = () => {
    logout();
    setIsOpen(false)
    navigate(`/${language}/`);
    window.location.reload();
  };
  const getData = async () => {
    try {
      const response = await fetch(`/api/user/wIzo9Vlr-GIpz-cuQ9-R1Q8-9I5KIyEFShl`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      });
      
      if (!response.ok) {
      throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      setUserData(responseData)
    } 
    catch (error) {
      console.error('Error:', error);
    }
}
// const [currentSkip, setCurrentSkip] = useState()
// const [currentLimit, setCurrentLimit] = useState()

const markedRead = async(data) => {
  try {
    const response = await fetch(`/api/notification/48f275c0-bc87-41ef-aef1-505f0a2373e6/${data}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    });
    
    if (!response.ok) {
    throw new Error('Network response was not ok');
    }
    const responseData = await response.json();
    // loadMore(currentSkip, currentLimit)
    getNotification()
  } 
  catch (error) {
    console.error('Error:', error);
  }
}
// const loadMore = async(skip = 0, limit = 10) => {
//   try {
//     const response = await fetch(`/api/notification//99f1f87d-41ad-411b-941d-e95822b9cc80/${skip}/${limit}/`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     });
    
//     if (!response.ok) {
//     throw new Error('Network response was not ok');
//     }
//     const responseData = await response.json();
//     console.log("🚀 ~ markedRead ~ responseData:", responseData)
//     setNotification(responseData)
//   } 
//   catch (error) {
//     console.error('Error:', error);
//   }
// }
const readAll = async() => {
  try {
    const response = await fetch(`/api/notification/9a60036b-a299-4d14-9f5e-3a5d78e5d07c`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    });
    
    if (!response.ok) {
    throw new Error('Network response was not ok');
    }
    const responseData = await response.json();
    console.log("🚀 ~ markedRead ~ responseData:", responseData)
    getNotification()
  } 
  catch (error) {
    console.error('Error:', error);
  }
}
useEffect(() => {
  const getEmail = () => {
    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      setUserEmail(JSON.parse(savedUserData));
    }
  };

  getEmail();
}, []); 

useEffect(() => {
  try {
    if (userEmail) {
      getData()
      getNotification()
      // loadMore()
    }
  } catch (error) {
    window.location.reload()
  }
}, [userEmail]);
const checkIcon = (type) => {
  switch(type){
    case 'reservation': return <i className="mr-3 fa-solid fa-bell-concierge"></i>
    case 'massage': return <i className="mr-3 fa-solid fa-spa"></i>
    case 'azcoin':return <img className="mr-3" src={azcoin} />
  }
}
const unreadCount = notificationCount?.data?.filter(item => item.read === false).length;
const sortedNotification = notificationCount?.data?.sort((a, b) => Number(a.read) - Number(b.read)) || [];
const [loading, setLoading] = useState(false);
const [visibleCount, setVisibleCount] = useState(10); 
const containerRef = useRef(null); 

const loadMoreData = () => {
    if (visibleCount < sortedNotification.length) {
        setLoading(true);
        setTimeout(() => {
            setVisibleCount((prevCount) => prevCount + 10); 
            setLoading(false);
        }, 1000); 
    }
};

const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    // Check if user has scrolled to the bottom of the container
    if (scrollHeight - scrollTop <= clientHeight && !loading) {
        loadMoreData(); // Load more items
    }
};
useEffect(() => {
    const container = containerRef.current;
    if (container && show) {
        container.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (container) {
            container.removeEventListener('scroll', handleScroll); 
        }
    };
}, [loading, show]); // Ensure loading state is tracked

useEffect(() => {
  if (show) {
      // Disable body scrolling
      document.body.style.overflow = 'hidden';
  } else {
      // Enable body scrolling
      document.body.style.overflow = '';
  }

  // Cleanup function to restore scrolling when the component unmounts
  return () => {
      document.body.style.overflow = '';
  };
}, [show]); // Run effect when 'show' changes

  return (
    <>
      <div className='top-header'>
                <div className='container-fluid'>
                    <div className='top-header-container row align-items-center justify-content-center'>
                        <div className='col-md-9'>
                        <p className="top-header-text">{t('header.top-header')}</p>
                        </div>
                        {userData ? (
                          <div className='btn-lang col-md-3'>
                            {location.pathname.includes("/recruitment") ? (
                          <button className ='btn-vie mr-3' onClick={()=>{changeLanguage('vi')}}></button> 
                            ) : (
                              <>
                              <button className ='btn-en mr-3' onClick={()=>{changeLanguage('en')}}></button>                
                              <button className ='btn-ja mr-3' onClick={()=>{changeLanguage('ja')}}></button>  
                              <button className ='btn-vie mr-3' onClick={()=>{changeLanguage('vi')}}></button> 
                              <button className ='btn-kor mr-3' onClick={()=>{changeLanguage('ko')}}></button> 
                              </>
                            )}

                          {!isProduct &&
                          <>
                          <div className="profile-container-1">
                          <p style={{color: "#fff", marginRight: 10}}>{userData?.data?.familyName} {userData?.data?.givenName}</p>
                          <img className="profile-image" src={userImage || userData?.data?.avatar || profilePicture} alt="Profile" style={{width: 30, height: 30, borderRadius: '50%'}}/>
                          <ul className="header__navbar-item-list" style={{width:200, top: 45, right: 20, borderBottomLeftRadius: 10, borderBottomRightRadius:10, zIndex:999, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                          <li className = "header__navbar-sub-link" style={{backgroundColor: '#fff'}} >
                          <Link style={{color: "#000"}} to ='#' onClick={() => {
                            navigate(`/${language}/setting/`)
                            window.location.reload()
                            }}><i className="fa-solid fa-gear" style={{color: '#482979', marginRight: 10}}></i>{t("login.setting")}</Link>
                          </li>
                          <li className = "header__navbar-sub-link"style={{backgroundColor: '#fff', borderBottomLeftRadius: 10, borderBottomRightRadius:10}}>
                          <Link  style={{color: "#000"}} onClick={handleLogout}><i className="fa-solid fa-right-from-bracket"style={{color: '#482979',marginRight: 10}}></i>{t("login.logout")}</Link>
                          </li>
                          </ul>
                          </div>
                          <div className="notification-container ml-4" ref={itemRef}>
                          <i style={{color: '#fff', fontSize: '1.6rem', cursor:'pointer'}} className="fa-solid fa-bell" onClick={toggleShow} />
                            {notificationCount?.data?.map((item, index) => (
                              <>
                              {item.read == false && (
                                <div className="d-flex justify-content-center align-items-center" style={{fontSize: '1rem',width: 18, height: 14, borderRadius: 50, backgroundColor: 'red', position: 'absolute', top:0, right:3}}>
                                <div key={index} style={{color: '#fff'}}>{unreadCount >= 10 ? unreadCount + '+' : unreadCount}</div>
                              </div>
                              )}
                            </>
                          ))}
                          {show && (
                            <div ref={containerRef} style={{position:'absolute', backgroundColor:'#fff', width:400,height: 400, overflowX: 'hidden',overflowY:'auto' ,top: 35, right: 15, borderBottomLeftRadius: 10, borderBottomRightRadius:10, zIndex:999, padding: 10}}>
                            <ul className="p-0">
                              <div className="d-flex align-items-center justify-content-between">
                              <div style={{fontSize: '1.4rem', fontWeight: 'bold', marginBottom: 5}}>Notification</div>
                              <div className="read-all" onClick={() => readAll()}>Mark as read all</div>
                              </div>
                              <div style={{borderBottom: '1px solid #c5c5c5'}}></div>
                              <div style={{width: 0, height: 0, borderLeft: '10px solid transparent', borderRight:'10px solid transparent', borderBottom: '10px solid #fff', right:0, position:'absolute', top:-5}}></div>
                              {sortedNotification.slice(0, visibleCount).map((item, index) => (
                                <li key={index} style={{backgroundColor: '#fff'}}>
                                  <Collapsible className="collapsible-noti" 
                                  onOpening={() =>{
                                    setTrigger(true)
                                    markedRead(item._id)
                                    getNotification()
                                  }} 
                                  onClose={() => setTrigger(false)} 
                                  trigger={<div className="d-flex align-items-center p-3" style={{color:'#482979', backgroundColor:item.read == false ? '#F5F5F7' : '#fff'}}>
                                    {checkIcon(item.type)} 
                                    {item.content[language].title} 
                                    <i style={{color: "#ccc", marginLeft: 10}} className={`fa-solid ${trigger ? 'fa-circle-chevron-up' : 'fa-circle-chevron-down'}`} /> 
                                    <i className="fa-solid fa-circle" style={{position: 'absolute', right: 15, color:'#482979', fontSize: 8, display:item.read == false ? 'block': 'none'}} />
                                  </div>}>
                                  <div style={{textAlign:'left', textColor:'#fff', marginLeft: 5, fontSize: '1.2rem'}}>{item.content[language].content.row1}</div>
                                  <div style={{textAlign:'left', textColor:'#fff', marginLeft: 5, fontSize: '1.2rem'}}>{item.content[language].content.row2}</div>
                                  <div style={{textAlign:'left', textColor:'#fff', marginLeft: 5, fontSize: '1.2rem'}}>{item.content[language].content.row3}</div>
                                  <div style={{textAlign:'left', textColor:'#fff', marginLeft: 5, fontSize: '1.2rem'}}>{item.content[language].content.row4}</div>
                                  <div style={{textAlign:'left', textColor:'#fff', marginLeft: 5, fontSize: '1.2rem'}}>{item.content[language].content.row5}</div>
                                  <div style={{textAlign:'left', textColor:'#fff', marginLeft: 5, fontSize: '1.2rem'}}>{item.content[language].content.row6}</div>
                                  <div style={{textAlign:'left', marginLeft: 5, fontSize: '1.4rem'}}>{item.content[language].content.row7}</div>
                                  </Collapsible>
                                  <div style={{borderBottom: '1px solid #c5c5c5'}}></div>
                                </li>
                              ))}
                              {/* <button onClick={(skip, limit) => {
                                setCurrentSkip(skip + 10)
                                setCurrentLimit(limit + 10)
                                loadMore(skip + 10, limit + 10)
                              }}>Load More</button> */}
                            </ul>
                            {loading && <div class="text-center">
                              <div class="spinner-grow" role="status" style={{color: '#482979'}}>
                              </div>
                                </div>
                                }
                            </div>
                            
                            )}
                            </div>        
                          </>
                          }
                          </div>
                        ) : (
                        <div className='btn-lang col-md-3'>
                         {location.pathname.includes("/recruitment") ? (
                          <button className ='btn-vie mr-3' onClick={()=>{changeLanguage('vi')}}></button> 
                            ) : (
                              <>
                              <button className ='btn-en mr-3' onClick={()=>{changeLanguage('en')}}></button>                
                              <button className ='btn-ja mr-3' onClick={()=>{changeLanguage('ja')}}></button>  
                              <button className ='btn-vie mr-3' onClick={()=>{changeLanguage('vi')}}></button> 
                              <button className ='btn-kor mr-3' onClick={()=>{changeLanguage('ko')}}></button> 
                              </>
                            )}
                        {!isProduct &&
                        <a href={`/${language}/login`} style={{textDecoration: 'none', color:'#482979', backgroundColor: "#fff", fontSize: '1.4rem', fontWeight:'bold', paddingLeft: 20, paddingRight: 20, borderRadius: 5}}>{t("login.login")}</a>
                        }
                        </div>
                        )}
                    </div>
                </div>
            </div>
      <div className="header_container">
      <div className="header-2">
        <div className="header__PC-2">
          <div className="header__logo">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Link to="/">
                    <img
                      src="/webp/azlogo.webp"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header__mobile-logo">
          <Link to="/">
            <img
               src="/webp/azlogo.webp"
              alt="logo"
            />
          </Link>
        </div>
        <div className="btn__header-mobile">
          <button
           onClick={toggleHeader}
           className="header__mobile">
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <HeaderMobile />
      </div>
      </div>
     <div className="is-sticky">
     <div className="container">
      <div className="header" style={{backgroundColor: backgroundColor}}>
        <div  className="header__PC">
             <div className="header__navbar-list mb-0 justify-content-start">
             <div className="header__navbar-item">
             <div className="header__logo">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Link to={`/${language}/`}>
                    <img
                       src="/webp/azlogo.webp"
                       alt="logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
                </div>
              </div>      
                  <div className="header__navbar-list mb-0">
                  <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: language === 'vi' ? 95 : 80}} to={`/${language}/`}>
                        {t("header.home")}
                      </Link>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: language === 'ja' || language ==='vi' ? 95 : 80}} to={`/${language}/news/`}>
                        {t("header.news")}
                      </Link>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: 110}} to={`/${language}/hotel-hcm/`}>
                        {t("header.hcm")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                        {hcm.map((item, index)=>(
                          <li className = "header__navbar-sub-link" key={index}>
                          <Link 
                          to ={item.link}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2"  to={`/${language}/hotel-hn/`}>
                        {t("header.hn")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:220}}>
                        {hn.map((item, index)=>(
                          <li className = "header__navbar-sub-link" key={index}>
                          <Link
                          to ={`/${language}${item.link}`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: 85}} to={`/${language}/hotel-dn/`}>
                        {t("header.dn")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                        {dn.map((item, index)=>(
                          <li className = "header__navbar-sub-link" key={index} >
                          <Link 
                          to ={`/${language}${item.link}`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: 95}} to={`/${language}/hotel-hp/`}>
                        {t("header.hp")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                        {hp.map((item, index)=>(
                          <li className = "header__navbar-sub-link" key={index}>
                          <Link 
                          to ={`/${language}${item.link}`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width:95  }} to="http://azumayacambodia.com/">
                        {t("header.cambodia")}
                      </Link>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: language === 'ja' || language ==='vi' ? 105 : 80}} to={`/${language}/feature/`}>
                        {t("header.feature")}
                      </Link>
                      <ul className="header__navbar-item-list">
                        {feature.map((item, index)=>(
                          <div className = "header__navbar-sub-link" key={index}>
                          <Link 
                          to ={`/${language}/feature/${item.id}`}> 
                          <span style={{backgroundColor:"#482979", padding:"5px", marginRight:"8px"}}>
                            {item.num}</span>{t(item.title)}
                            </Link>
                            </div>
                        ))}
                        </ul>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: language === 'ja' ? 110 : 80}} to={`/${language}/service/`}>
                        {t("header.service")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width: 240}}>
                        {service.map((item, index)=>(
                          <li className = "header__navbar-sub-link" key={index}>
                          <Link 
                          to = {`/${language}${item.link}/`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: language === 'ja' || language ==='vi' ? 95 : 80}} to={`/${language}/contract/`}>
                        {t("header.contract")}
                      </Link>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: 110}} to={`/${language}/reservation/`}>
                        {t("header.reservation")}
                      </Link>
                    </div>
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`${language}/q&a/`}>
                        {t("header.policies")}
                      </Link>
                    </div>
                    {language === 'vi' && (
                    <div className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width: 110 }}to={`/vi/recruitment/`}>
                        {t("header.recruitment")}
                      </Link>
                    </div>
                    )}
                  </div>
                  </div>
            </div>
          </div>
     </div>
    </>
  );
}
export default Header;